import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import emailjs from "@emailjs/browser";

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 672px;
  background: #141414;
  backdrop-filter: blur(50px);
  overflow-x: hidden;
`;

const LeftContainer = styled.div`
  display: flex;
  width: 65vw;
`;

const RightFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 34.6vw;
  height: 672px;
`;

const LeftSpanDiv = styled.div`
  margin-top: 122px;
  margin-left: 164px;
  display: flex;
  flex-direction: column;
  gap: 336px;
`
const SpanStyle = styled.span`
  color: var(--text, #e7e7e7);
  font-family: Pretendard_Black;
  font-size: 60px;
  font-weight: 900;
  line-height: 135%;
  letter-spacing: -1px;
`;
const Span2Style = styled.span`
width: 315px;

color: #BDBDBD;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 18.9px */
letter-spacing: -0.56px;
`
const RightSpanStyle = styled.span`
  color: #d9d9d9;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 300;
  line-height: 135%;
  letter-spacing: -0.48px;
  padding: 6px 16px;
`;

const FirstContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #686868;
  width: 100%;
`;

const SecondContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const EmailContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #686868;
  border-right: 1px solid #686868;
  border-bottom: 1px solid #686868;
`;

const PhoneNumberContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #686868;
  border-bottom: 1px solid #686868;
`;

const ThirdContainer = styled.div`
  padding: 16px;
  height: 100%;
  border-left: 1px solid #686868;
  border-right: 1px solid #686868;
  background-color: ${props => (props.isFocused ? "#f0f0f0" : "transparent")};

  &:hover {
    background-color: ${props => (props.isFocused ? "" : "rgba(255, 255, 255, 0.08);")};
  }
`;

const MessageContainer = styled.textarea`
  width: 100%;
  height: 99%;
  background-color: transparent;
  border: none;
  resize: none;
  color: var(--text, #e7e7e7);
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: -0.56px;

  &:focus {
    background-color: #f0f0f0;
    outline: none;
    color: #141414;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.56px;
  }
`;

const FirstInput = styled.input`
  background-color: transparent;
  border: none;
  border-top: 1px solid #686868;
  height: 51px;
  padding: 0px 16px 0px 16px;
  color: var(--text, #e7e7e7);
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: -0.56px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }

  &:focus {
    background-color: white;
    outline: none;
    color: #141414;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.56px;
  }
`;

const SendInput = styled.input`
  width: 100%;
  height: 20%;
  border: 1px solid #686868;
  background: ${props => (props.sent ? "#44E46E" : "#454545")};
  color: #686868;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: -0.48px;

  &:hover {
    background: var(--text, #e7e7e7);
    color: #141414;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -0.48px;
  }
`;

const Contact = ({contactRef}) => {
  const form = useRef();
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [isMessageFocused, setIsMessageFocused] = useState(false); // focus
  /* const [onHoverCategory, setOnHoverCategory] = useState(false); // hover */

  useEffect(() => {
    let timeout;
    if (isMessageSent) {
      timeout = setTimeout(() => {
        setIsMessageSent(false);
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [isMessageSent]);

  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current.name.value.trim();
    const email = form.current.email.value.trim();
    const phonenumber = form.current.phonenumber.value.trim();
    const message = form.current.message.value.trim();

    if (!name || !email || !message || !phonenumber) {
      alert("모든 내용을 채워주세요.");
      return;
    }

    emailjs
      .sendForm(
        `${process.env.REACT_APP_EMAILJS_SERVICE_ID}`,
        `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`,
        form.current,
        `${process.env.REACT_APP_EMAILJS_PUBLIC_KEY}`
      )
      .then(
        (result) => {
          alert("메세지가 보내졌습니다.");
          setIsMessageSent(true);
          form.current.reset();
        },
        (error) => {
          alert("전송 오류 관리자에게 문의해주세요.");
        }
      );
  };

  return (
    <ContactContainer ref={contactRef}>
      <LeftContainer>
        <LeftSpanDiv>
          <SpanStyle>Contact us!</SpanStyle>
          <Span2Style>
            궁금한 점이 있거나 새로운 프로젝트를 시작하고 싶다면
            <br/> 저희에게 메세지를 보내주세요
          </Span2Style>
        </LeftSpanDiv> 
      </LeftContainer>

      <RightFormContainer ref={form} onSubmit={sendEmail}>
        <FirstContainer>
          <RightSpanStyle>이름 또는 회사명</RightSpanStyle>
          <FirstInput type="text" name="name" />
        </FirstContainer>
        <SecondContainer>
          <EmailContainer>
            <RightSpanStyle>이메일</RightSpanStyle>
            <FirstInput type="email" name="email" />
          </EmailContainer>
          <PhoneNumberContainer>
            <RightSpanStyle>연락처</RightSpanStyle>
            <FirstInput type="text" name="phonenumber" />
          </PhoneNumberContainer>
        </SecondContainer>
        <ThirdContainer
          isFocused={isMessageFocused}
          >
        <MessageContainer
            name="message"
            placeholder="여기에 메세지를 적어주세요."
            onFocus={() => setIsMessageFocused(true)}
            onBlur={() => setIsMessageFocused(false)}
          ></MessageContainer>
        </ThirdContainer>
        <SendInput
          type="submit"
          value="메세지 보내기"
          sent={isMessageSent}
        ></SendInput>
      </RightFormContainer>
    </ContactContainer>
  );
};

export default Contact;
