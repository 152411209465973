import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { HeaderStarIcon, Header_JOYS_Logo } from '../../../style/img';
import { setheaderinfo } from '../../../module/miain/headerinfo';
import { useDispatch, useSelector } from 'react-redux';
import { setagreeclickfalse } from '../../../module/miain/messages/messagesinfo';

const StickyHeaderContainer = styled.div`
position: fixed;
top: 0;
display: flex;
width: 99vw;
height: 49px;
align-items: center;
//
border-top: 1px solid rgba(255, 255, 255, 0.60);
border-bottom: 1px solid rgba(255, 255, 255, 0.60);
//border-left: 1px solid rgba(255, 255, 255, 0.60);
background: rgba(255, 255, 255, 0.01);
backdrop-filter: blur(15px);
z-index: 1000;
&:hover{
    cursor:pointer;
}
`
const FirstHeaderBoxStyle = styled.div`
display: flex;
align-items: center;
margin-left: 16px;
height: 49px;
flex-grow: 9;
//border-right: 1px solid rgba(255, 255, 255, 0.60);
`

const HeaderBoxStyle = styled.div`
height: 49px;
display: flex;
flex-grow: ${(props) => (props.selected ? '9' : '0.5')};
align-items: center;
justify-content: ${(props) => (props.selected ? '' : 'center')};
margin-left: ${(props) => (props.selected ? '20px' : '')};
gap: 10px;
border-left: 1px solid rgba(255, 255, 255, 0.60);
/* &:hover{
    background-color: black;
} */
`
const StarImg = styled.img`
display: ${(props) => (props.selected ? 'flex' : 'none')};
`
const HeaderSpanStyle = styled.span`
color: ${(props) => (props.selected ? '#FFF' : 'rgba(255, 255, 255, 0.60)')};
text-align: center;
text-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.40);
font-family: 'Pretendard';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.98px;
`
const SecondHeaderSpanStyle = styled.span`
color: ${(props) => (props.selected ? '#44E46E' : 'rgba(68, 228, 110, 0.60)')};
text-align: center;
font-family: 'Pretendard';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.98px;
`

const categories = [
/*     { name: "JOYS" },
    { name: "조이스 AI 모델과 이미지 생성 경험하기" }, */
    { name: "Tech Part" },
    { name: "About us" },
    { name: "Projects" },
    { name: "Team Members" },
    { name: "Contact" },
  ];

const Header = ({ scrollRef, joysRef, aiRef, techPartRef, aboutUsRef, projectsRef, teamMembersRef,contactRef }) => {
    const { headerinfo } = useSelector((state) => state.headerinfo);
    const dispatch = useDispatch();
    const {agreeclick} = useSelector((state) => state.messagesinfo);
    const [category,setCategory] = useState("JOYS");

    console.log(scrollRef, agreeclick)


    const scrollToRef = (ref) => {
        const offset = ref.current.getBoundingClientRect().top;
        window.scrollTo({
            top: window.pageYOffset + offset - 49,
            behavior: "smooth",
        });
    };
    
    const onCategoryClick = (name) => {
        dispatch(setagreeclickfalse());
        switch (name) {
            case "JOYS":
                joysRef.current.scrollIntoView({ behavior: "smooth" });
                break;
            case "AI":
                scrollToRef(aiRef);
                break;
            case "Tech Part":
                scrollToRef(techPartRef);
                break;
            case "About us":
                scrollToRef(aboutUsRef);
                break;
            case "Projects":
                scrollToRef(projectsRef);
                break;
            case "Team Members":
                scrollToRef(teamMembersRef);
                break;
            case "Contact":
                scrollToRef(contactRef);
                break;
            default:
                break;
        }
    };

/*     useEffect(()=>{
        console.log(headerinfo)
    },[headerinfo]) */
    useEffect(() => {
            if(scrollRef ==='Introduce'){
                setCategory("JOYS");
            }
            if(scrollRef ==='Messages'){
                setCategory("AI");
            }
            if(scrollRef ==='TechPart'){
                setCategory("Tech Part");
            }
            if(scrollRef ==='AboutUs'){
                setCategory("About us");
            }
            if(scrollRef ==='Projects'){
                setCategory("Projects");
            }
            if(scrollRef ==='TeamMembers'){
                setCategory("Team Members");
            }
            if(scrollRef ==='Contact'){
                setCategory("Contact");
            }
    }, [scrollRef]);

    useEffect(()=>{
        if(agreeclick){
            setCategory("AI")
        }else{
            setCategory("JOYS");
        }
    },[agreeclick])

  return (
    <StickyHeaderContainer>
        <FirstHeaderBoxStyle
        onClick={() => {
            onCategoryClick("JOYS");
            }}
        >
            <HeaderSpanStyle>
                <img src={Header_JOYS_Logo}/>
            </HeaderSpanStyle>
        </FirstHeaderBoxStyle>
        <HeaderBoxStyle
        selected={category === "AI"}
        onClick={() => {
            onCategoryClick("AI");
            }}
        >
            <StarImg
                    src={HeaderStarIcon}
                    selected={category === "AI"}/>
            <SecondHeaderSpanStyle
            selected={category === "AI"}>
                조이스 AI 모델과 이미지 생성 경험하기
            </SecondHeaderSpanStyle>
        </HeaderBoxStyle>
        {categories &&
            categories.map((c, idx) => (
                <HeaderBoxStyle
                    key={idx}
                    name={c.name}
                    onClick={() => {
                    onCategoryClick(c.name);
                    }}
                    selected={category === c.name}
                >
                    <StarImg
                        src={HeaderStarIcon}
                        selected={category === c.name}/>
                    <HeaderSpanStyle
                        selected={category === c.name}
                    >
                        {c.name}
                    </HeaderSpanStyle>
                </HeaderBoxStyle>
                ))}
    </StickyHeaderContainer>
  );
};

export default Header;