import React from 'react';
import styled from "@emotion/styled/macro";

const FooterContainer = styled.div`
width: 100%;
height: 100px;
display: flex;
/* padding: 144.5px 35px 143.5px 35px; */
justify-content: center;
align-items: center;

background: rgba(255, 255, 255, 0.01);
backdrop-filter: blur(80px);
gap: 50px;
`

const FooterSpan = styled.span`
color: #787878;
font-family: pretendard;
font-size: 9px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 12.15px */
letter-spacing: -0.36px;
`

const Footer = () => {


  return (
    <FooterContainer>
        <FooterSpan>주식회사 조이스</FooterSpan>
        <FooterSpan>김종우</FooterSpan>
        <FooterSpan>651-86-02509</FooterSpan>
        <FooterSpan>COPYRIGHT © 2023 joys-ai.com ALL RIGHTS RESERVED</FooterSpan>
    </FooterContainer>
  );
};

export default Footer;