import React, { useState } from 'react';
import styled from "@emotion/styled/macro";
import { FineTuning_AI_Hamburger_Img, FineTuning_AI_Kimchi_Img, FineTuning_AI_Main_Img, } from '../../style/img';

const FineTuningAIContainer = styled.div`
width: auto;

background: #E2E2E2;
`
const FineTuningAIFirstSpan = styled.span`
margin-top: 81px;
color: #141414;
font-family: 'Pretendard';
font-size: 30px;
font-style: normal;
font-weight: 900;
line-height: normal;
letter-spacing: -0.9px;
`
const FineTuningAISpanDivStyle = styled.div`
display: flex;
flex-direction: column;
margin-left: 80px;
gap: 30px;
margin-bottom: 66px;
`
const FineTuningAISubSpanStyle = styled.div`
margin-top: 111px;
margin-left: 80px;
margin-bottom: 66px;
`

const FineTuningAISecondSpan = styled.span`
color: #595959;
font-family: 'Pretendard';
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
`
const FineTuningAIMainStyle = styled.div`

`
const FineTuningAISubStyle = styled.div`
height: 800px;
`
const ImgDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 40px;
`
const SubImgDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`
const SubImgSpan = styled.span`
margin-top: 47px;
color: #595959;
text-align: center;
font-family: 'Pretendard';
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`

const FineTuningAI = () => {

  return (
    <FineTuningAIContainer>
        <FineTuningAIMainStyle>
            <FineTuningAISpanDivStyle>
                <FineTuningAIFirstSpan>Fine - tuning Images</FineTuningAIFirstSpan>
                <FineTuningAISecondSpan>
                    Seed based modification,
                    <br />
                    generation variation
                </FineTuningAISecondSpan>
            </FineTuningAISpanDivStyle>
            <ImgDiv>
                <img src = {FineTuning_AI_Main_Img} />
            </ImgDiv>
        </FineTuningAIMainStyle>

        <FineTuningAISubStyle>
            <FineTuningAISubSpanStyle>
                <FineTuningAISecondSpan>
                    Seed based modification,
                    <br />
                    generation variation
                </FineTuningAISecondSpan>
            </FineTuningAISubSpanStyle>
            <ImgDiv>
                <SubImgDiv>
                    <img src = {FineTuning_AI_Kimchi_Img} />
                    <SubImgSpan>Kimchi Product Shot</SubImgSpan>
                </SubImgDiv>
                <SubImgDiv>
                    <img src = {FineTuning_AI_Hamburger_Img} />
                    <SubImgSpan>Hamburger Product Shot</SubImgSpan>
                </SubImgDiv>                
            </ImgDiv>
        </FineTuningAISubStyle>
    </FineTuningAIContainer>
  );
};

export default FineTuningAI;