import React, { useState } from 'react';
import styled from "@emotion/styled/macro";
import { AboutUsBackGroundImg, S_img } from '../../style/img';

const SHortIntroduceContainer = styled.div`
overflow-x: hidden;
display: inline-flex;
width: 100%;
height: 630px;
justify-content: center;
align-items: center;
flex-direction: row;
background: #141414;
position: relative;
`
const BackgroundImageDivStyle = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: absolute;
top: 0;
left: 0;

width: 100%;
height: 100%;
background-image: url(${AboutUsBackGroundImg});

opacity: ${(props) => (props.onHoverCategory ? `0.3` : '0')};
background-size: cover;
background-position: center;
background-repeat: no-repeat;
`

const ShortIntroduceMainStyle = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 40px;
//
`
const TopStyle = styled.div`

`
const TopSpan = styled.span`
color: var(--text, #E7E7E7);
text-align: center;
font-family: Pretendard_Black;
font-size: 52px;
font-style: normal;
font-weight: 900;
line-height: 135%;
letter-spacing: -0.3px;
`

const BottomStyle = styled.div`

`
const BottomSpan = styled.span`
color: var(--text, #E7E7E7);
font-family: Pretendard_Regular;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 40.5px */
letter-spacing: -0.3px;
`
const AboutUs = ({aboutUsRef}) => {
    const [onHoverCategory, setOnHoverCategory] = useState(false);

  return (
    <SHortIntroduceContainer
        ref = {aboutUsRef}>
        <BackgroundImageDivStyle
                onHoverCategory = {onHoverCategory}
                onMouseEnter={() => setOnHoverCategory(true)}
                onMouseLeave={() => setOnHoverCategory(false)}>

        </BackgroundImageDivStyle>
        <ShortIntroduceMainStyle>
            <TopStyle>
                <TopSpan>
                    콘텐츠 생성 AI 플랫폼 전문기업 조이스
                </TopSpan>
            </TopStyle>
            <BottomStyle>
                <BottomSpan>
                    대화형 챗봇 및 이미지 콘텐츠 생성
                </BottomSpan>
            </BottomStyle>
        </ShortIntroduceMainStyle>
    </SHortIntroduceContainer>
  );
};

export default AboutUs;