const initialState = {
  headerinfo: "JOYS",
};

const SETHEADERINFO = "headerinfo/SETHEADERINFO";

export const setheaderinfo = (name) => ({ type: SETHEADERINFO , name });

export default function headerinfo(state = initialState, action) {
    switch (action.type) {
      case SETHEADERINFO:
        return { ...state, headerinfo: action.name };
  
      default:
        return state;
    }
  }
