import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { Avadot_Logo, Bossline_Logo, Storist_Logo } from '../../../style/img';
import ProjectLogo from './ProjectLogo';

const ProjectsContainer = styled.div`
width: auto;
height: auto;
display: flex;
flex-direction: column;
/* background: #141414; */
background: black;
overflow-x: hidden;
overflow-y: hidden;
`
const TopStyle = styled.div`
margin-top: 90px;
display: flex;
justify-content: space-between;
top: 0;
//
color: #E9E9E9;
font-family: 'Pretendard';
font-size: 96px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: -2.88px;
height: 688px;

`
const LeftDiv = styled.div`
margin-left: 163px;
display: flex;
flex-direction: column;
width:490px;
top: 0;
`
const LeftSpan = styled.span`
//
color: #E7E7E7;
font-family: Pretendard_Black;
font-size: 60px;
font-style: normal;
font-weight: 900;
line-height: 135%; /* 81px */
letter-spacing: -0.7px;
//
top: 0;
`
const RightDiv = styled.div`
display: flex;
flex-direction: column;
margin-top: 319px;
/* margin-top: 500px; */
margin-right: 165px;
gap: 16px;
width: 337px;
`
const RightSpan = styled.span`
//
color: #E9E9E9;
font-family: 'Pretendard';
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 135%; /* 21.6px */
letter-spacing: -0.48px;
//
`
const RightContactDiv = styled.div`
display: flex;
width: 327px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
border: 1px solid var(--text, #E7E7E7);
//
color: var(--text, #E7E7E7);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 135%; /* 21.6px */
letter-spacing: -0.48px;
//
&:hover{
  cursor: pointer;
  color: #141414;
font-family: Pretendard_Regular;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 135%; /* 21.6px */
letter-spacing: -0.16px;

  background: #44E46E;
}
`
const RightContactSpan = styled.span`
color: var(--text, #E7E7E7);
font-family: Pretendard_Regular;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 21.6px */
letter-spacing: -0.16px;
`

const Projects = ({ scrollTop, projectsRef, contactRef }) => {

  const scrollToRef = (ref) => {
    const offset = ref.current.getBoundingClientRect().top;
    window.scrollTo({
        top: window.pageYOffset + offset - 49,
        behavior: "smooth",
    });
};

  const onClickContact = () => {
    scrollToRef(contactRef);
    /* window.scrollTo({top:7654}); */
  }
  return (
    <ProjectsContainer ref={projectsRef}
    id='projects-scroll'>
        <TopStyle>
            <LeftDiv>
                <LeftSpan>
                    Take a look
                <br></br>
                  what we've done
                <br></br>
                so far.
                </LeftSpan>
            </LeftDiv>

            <RightDiv>
            <RightSpan>
              조이스는 생성형 AI 기술을 활용한 다양한 협업 프로젝트도 진행합니다. 프로젝트의 기획부터 디자인, 완성된 프로덕트까지 팀 내에서 모든 프로세스를 주도적으로 진행할 수 있습니다. 
            </RightSpan>
            <RightContactDiv
            onClick={onClickContact}>
              <RightContactSpan>
                Contact 하러 가기
              </RightContactSpan>
            </RightContactDiv>
            </RightDiv>
        </TopStyle>
        <ProjectLogo scrollTop = {scrollTop}/>
    </ProjectsContainer>
  );
};

export default Projects;