const initialState = {
    agreeclick: false,
  };
  
const SETAGREECLICKFALSE = "messagesinfo/SETAGREECLICKFALSE";
const SETAGREECLICKTRUE = "messagesinfo/SETAGREECLICKTRUE";
  
  export const setagreeclickfalse = () => ({
    type: SETAGREECLICKFALSE,
  });
  
  export const setagreeclicktrue = () => ({
    type: SETAGREECLICKTRUE,
  });

  export default function messagesinfo(state = initialState, action) {
      switch (action.type) {
        case SETAGREECLICKFALSE:
            return { ...state, agreeclick: false };
        case SETAGREECLICKTRUE:
            return { ...state, agreeclick: true };
    
        default:
          return state;
      }
    }
  