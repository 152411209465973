import React, { useEffect, useRef, useState } from 'react';
import styled from "@emotion/styled/macro";
import { ArrowLine_CEO, ArrowLine_CSO, ArrowLine_CTO, ArrowLine_Designer1, ArrowLine_Developer1, ArrowLine_Developer2, ArrowLine_Manager, CEOEmoji, CSOEmoji, CTOEmoji, Designer1Emoji, Developer1Emoji, Developer2Emoji, Joys_Emoji_Small_Img, ManagerEmoji, Mouse_Event_Text, TeamMembersBackGroundImg } from '../../../style/img';
import EmojiExplain from './EmojiExplain';

const TeamMembersContainer = styled.div`
width: 100%;
height: 864px;
background-color: black;
overflow-y: hidden;
`

const TeamMembersLogoContainer = styled.div`
margin-top: 80px;
margin-left: 5%;
`
const TeamMemersLogoSpanStyle = styled.span`
color: #E7E7E7;
font-family: 'Pretendard_Black';
font-size: 60px;
//font-style: normal;
font-weight: 900;
line-height: 135%; /* 81px */
letter-spacing: -1px;
`

const EmojiContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;

margin-top: 539px;
margin-left: 5%;

width: 165px;
height: 165px;
flex-shrink: 0;
/* background-color: red; */
`
const EmojiImg = styled.img`
  position: absolute;
  margin-top: ${(props) => props.marginTop}px;
  padding: 0.357px 0px 15.278px 0px;
`
const EmojiText = styled.img`
`
const TeamMembersImgContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 679px;
`
const TeamMembersHoverContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 1536px;
height: 679px;
/* background-color: red; */
`
const TeamMembersEmojiSet = styled.div`
background-image: url(${TeamMembersBackGroundImg});
background-repeat: no-repeat;
/* background-position: 0 50%; */
width: 807.125px;
height: 679px;
position: relative;
top: -70px;
/* flex-shrink: 0; */
`

//Emoji
const CEOEmojiImg = styled.img`
position: absolute;
width: 69px;
height: 69px;
top: 277px;
left: 101px;
z-index: 11;

filter: drop-shadow(0px 50px 5px 1px #000);
`
const CTOEmojiImg = styled.img`
position: absolute;
width: 69px;
height: 69px;
top: 320px;
left: 296px;
z-index: 2;
/* &:hover{
  cursor: pointer;
} */
`
const Developer1EmojiImg = styled.img`
position: absolute;
width: 69px;
height: 69px;
top: 404px;
left: 360px;
z-index: 2;
`
const Developer2EmojiImg = styled.img`
position: absolute;
width: 69px;
height: 69px;
top: 505px;
left: 368px;
z-index: 2;
`
const CSOEmojiEmojiImg = styled.img`
position: absolute;
width: 69px;
height: 69px;
top: 264px;
left: 601px;
z-index: 2;
`
const Designer1EmojiImg = styled.img`
position: absolute;
width: 69px;
height: 69px;
top: 147.4px;
left: 553px;
z-index: 2;
`
const ManagerEmojiImg = styled.img`
position: absolute;
width: 69px;
height: 69px;
top: 370px;
left: 627px;
z-index: 2;
`
const DropShadowStyle = styled.div`
position: absolute;
width: 73.92px;
height: 22.526px;
top: ${(props) => (props.top)};
left: ${(props) => (props.left)};
background: radial-gradient(50% 50.00% at 50% 50.00%, #1c1c1c 0%, rgba(95, 95, 95, 0.00) 100%);
background-blend-mode: multiply;
`
const TeamMembersHover = styled.div`
position: absolute;
width: 1536px;
height: 864px;
/* background-color: red; */
`
const Span1 = styled.span`
color:white;
`
const MemberBoxStyle = styled.div`
position:absolute;
display: flex;
width: 307px;
padding: 24px 17px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 16px;
border: 1px solid gray;
border-radius: 10px;
background: linear-gradient(138deg, rgba(14, 14, 14, 0.21) 0%, #212C23 100%) border-box

;



backdrop-filter: blur(8.5px);
transition: opacity , 0.4s ease, transform 0.4s ease;
opacity: ${(props) => (props.opacity !== undefined ? props.opacity : 0)};
z-index: 90;
top: ${(props) => {
    if (props.category === "CEO") return "194px";
    else if (props.category === "CTO") return "442px";
    else if (props.category === "Developer1") return "612px";
    else if (props.category === "Developer2") return "566px";
    else if (props.category === "Designer1") return "107px";
    else if (props.category === "CSO") return "215px";
    else if (props.category === "Manager") return "358px";
    else return "0px";
  }};
  left: ${(props) => {
    if (props.category === "CEO") return "45px";
    else if (props.category === "CTO") return "140px";
    else if (props.category === "Developer1") return "228px";
    else if (props.category === "Developer2") return "937px";
    else if (props.category === "Designer1") return "1067px";
    else if (props.category === "CSO") return "1118px";
    else if (props.category === "Manager") return "1131px";
    else return "0px";
  }};
`
const MemberBoxTopStyle = styled.div`
display: flex;
align-items: center;
gap: 14px;

color: var(--white, #F5F5F5);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 135.867%; /* 27.173px */
letter-spacing: -1px;
`
const MemberBoxDetail1Style = styled.div`
display: flex;
flex-direction: column;

color: var(--white, #F5F5F5);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135.867%; /* 21.739px */
letter-spacing: -0.8px;
`
const MemberBoxDetail2Style = styled.div`
display: flex;
flex-direction: column;
color: var(--g-2, #B3B3B3);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
letter-spacing: -0.7px;
`
const OnHoverEffect = styled.div`
position: absolute;
width: 90px;
height: 90px;
filter: blur(14px);
background-color: #44E46E;
border-radius: 45px;
top: ${(props) => (props.top)};
left: ${(props) => (props.left)};
opacity: ${(props) => (props.opacity)};
`
const ArrowLineImg = styled.img`
position: absolute;
top: ${(props) => (props.top)};
left: ${(props) => (props.left)};
opacity: ${(props) => (props.opacity)};
z-index: 80;
`
const BorderStyle = styled.div`
border-right: 1px solid #F5F5F5;
height: 15px;
`
const members = [
  {
    category: "CEO",
    name: "김종우",
    position: "CEO",
    detail1: [
      "챗봇 개발자 (AI)",
      "백엔드 개발자 (서버, DB)",
      "디지털 마케팅"
    ],
    detail2:[
      "UCLA Computer Science",
      "인공지능 특허 21회 출원/등록",
      "B2B 인공지능 챗봇 서비스 마인드셋 CTO",
      "(아모레퍼시픽, BHC, 현대자동차 공급)",
      "MAU 200만 글로벌 게임 서비스 와썹지지 CTO",
      "(아시아, 유럽, 미국 런칭)",
      "페르소나 챗봇 서비스 도어오픈 CTO",
      "뮤직 NFT 3PM 테크티널 마케터"
    ]
  },
  {
    category: "CTO",
    name: "윤창희",
    position: "CTO",
    detail1: [
      "풀스택 개발자 (프론트,백엔드 총괄)",
      "챗봇 AI 알고리즘, 데이터 사이언티스트"
    ],
    detail2:[
      "UCLA Computer Science",
      "인공지능 특허 13회 출원/등록",
      "B2B 인공지능 챗봇 서비스 마인드셋 수석 개발자",
      "(아모레퍼시픽, BHC, 현대자동차 공급)",
      "MAU 200만 글로벌 게임 서비스 와썹지지 CTO",
      "(아시아, 유럽, 미국 런칭)"
    ]
  },
  {
    category: "Developer1",
    name: "김태균",
    position: "Developer",
    detail1: [
      "프론트엔드 엔지니어",
      "WebRTC 엔지니어"
    ],
    detail2:[
      "강릉원주대학교 컴퓨터공학과"
    ]
  },
  {
    category: "Developer2",
    name: "이준희",
    position: "Developer",
    detail1: [
      "프론트엔드 개발자 (React 및 클라이언트 사이드)",
      "백엔드 개발자 (서버,DB,클라이언트 API )"
    ],
    detail2:[
      "유한대 정보통신공학과",
      "정보처리기사 및 국가공인 리눅스 마스터 2급"
    ]
  },
  {
    category: "Designer1",
    name: "표혜인",
    position: "Product Designer",
    detail1: [
      "UI / UX 디자이너",
      "서비스 기획"
    ],
    detail2:[
      "UdK Berlin Product Design",
      "Dooropen UI / UX 디자이너",
      "쏘랩 UI 디자이너",
      "1002 인턴십",
      "Donut System Illustrator"
    ]
  },
  {
    category: "CSO",
    name: "김윤지",
    position: "CSO",
    detail1: [
      "비즈니스 전략 및 커뮤니케이션",
      "대내외 법적 이슈 컨트롤",
      "해외 진출 시장 분석"
    ],
    detail2:[
      "Northwestern Univ. LL.M",
      "KAIST Master of IP",
      "Free University Berlin 경제학",
      "IOC 평창 조직위, 국제박람회",
      "및 커머스 홍보국 커뮤니케이션",
      "핀테크 기업설립",
      "스타트업 해외 진출 및 시장분석자문"
    ]
  },
  {
    category: "Manager",
    name: "이준한",
    position: "Global Sales Manager",
    detail1: [
      "글로벌 오퍼레이션",
      "중국, 일본, 타이완 등"
    ],
    detail2:[
      "Beijing University Chinese medicine",
      "Yoyou, inc (Japan)  COO",
      "Booking.com (Japan) E-커머스 매니저",
      "차우림 수출입 관리 및 해외운영 및 마케팅"
    ]
  },
]
const TeamMembers = ({teamMembersRef}) => {
  const [hoveredCategory, setHoveredCategory] = useState(null);

  return (
    <TeamMembersContainer ref={teamMembersRef}>
        <TeamMembersLogoContainer>
            <TeamMemersLogoSpanStyle>
                Team Members
            </TeamMemersLogoSpanStyle>
        </TeamMembersLogoContainer>

        <TeamMembersImgContainer>
          <TeamMembersHoverContainer>
            <TeamMembersEmojiSet>
                <CEOEmojiImg src = {CEOEmoji} 
                  onMouseEnter={() => setHoveredCategory("CEO")}
                  onMouseLeave={() => setHoveredCategory(null)}/>
                <DropShadowStyle top={"333px"} left={"98px"}/>
                <OnHoverEffect 
                  top={"267px"} left={"90px"}
                  opacity={hoveredCategory === "CEO" ? 1 : 0}>
                </OnHoverEffect>
                <ArrowLineImg 
                  src = {ArrowLine_CEO}
                  top={"297px"} left={"19px"}
                  opacity={hoveredCategory === "CEO" ? 1 : 0}/>

                <CTOEmojiImg src = {CTOEmoji} 
                  onMouseEnter={() => setHoveredCategory("CTO")}
                  onMouseLeave={() => setHoveredCategory(null)}/>
                <DropShadowStyle top={"374px"} left={"294px"}/>
                <OnHoverEffect
                  top={"311px"} left={"287px"}
                  opacity={hoveredCategory === "CTO" ? 1 : 0}>
                </OnHoverEffect>
                <ArrowLineImg 
                  src = {ArrowLine_CTO}
                  top={"350px"} left={"-53px"}
                  opacity={hoveredCategory === "CTO" ? 1 : 0}/>

                <Developer1EmojiImg src = {Developer1Emoji} 
                  onMouseEnter={() => setHoveredCategory("Developer1")}
                  onMouseLeave={() => setHoveredCategory(null)}/>
                <DropShadowStyle top={"460px"} left={"357px"}/>
                <OnHoverEffect
                  top={"398px"} left={"352px"}
                  opacity={hoveredCategory === "Developer1" ? 1 : 0}>
                </OnHoverEffect>
                <ArrowLineImg 
                  src = {ArrowLine_Developer1}
                  top={"445px"} left={"185px"}
                  opacity={hoveredCategory === "Developer1" ? 1 : 0}/>

                <Developer2EmojiImg src = {Developer2Emoji} 
                  onMouseEnter={() => setHoveredCategory("Developer2")}
                  onMouseLeave={() => setHoveredCategory(null)}/>
                <DropShadowStyle top={"561px"} left={"366px"}/>
                <OnHoverEffect 
                  top={"497px"} left={"359px"}
                  opacity={hoveredCategory === "Developer2" ? 1 : 0}>
                </OnHoverEffect>
                <ArrowLineImg 
                  src = {ArrowLine_Developer2}
                  top={"550px"} left={"425px"}
                  opacity={hoveredCategory === "Developer2" ? 1 : 0}/>

                <Designer1EmojiImg src = {Designer1Emoji} 
                  onMouseEnter={() => setHoveredCategory("Designer1")}
                  onMouseLeave={() => setHoveredCategory(null)}/>
                <DropShadowStyle top={"202px"} left={"551px"}/>
                <OnHoverEffect top={"136px"} left={"543px"}
                  opacity={hoveredCategory === "Designer1" ? 1 : 0}>
                </OnHoverEffect>
                <ArrowLineImg 
                  src = {ArrowLine_Designer1}
                  top={"135px"} left={"610px"}
                  opacity={hoveredCategory === "Designer1" ? 1 : 0}/>

                <CSOEmojiEmojiImg src = {CSOEmoji} 
                  onMouseEnter={() => setHoveredCategory("CSO")}
                  onMouseLeave={() => setHoveredCategory(null)}/>
                <DropShadowStyle top={"317px"} left={"600px"}/>
                <OnHoverEffect
                  top={"256px"} left={"591px"}
                  opacity={hoveredCategory === "CSO" ? 1 : 0}>
                </OnHoverEffect>
                <ArrowLineImg 
                  src = {ArrowLine_CSO}
                  top={"268px"} left={"663px"}
                  opacity={hoveredCategory === "CSO" ? 1 : 0}/>

                <ManagerEmojiImg src = {ManagerEmoji} 
                  onMouseEnter={() => setHoveredCategory("Manager")}
                  onMouseLeave={() => setHoveredCategory(null)}/>
                <DropShadowStyle top={"431px"} left={"630px"}/>    
                <OnHoverEffect 
                  top={"355px"} left={"615px"}
                  opacity={hoveredCategory === "Manager" ? 1 : 0}>
                </OnHoverEffect>  
                <ArrowLineImg 
                  src = {ArrowLine_Manager}
                  top={"400px"} left={"683px"}
                  opacity={hoveredCategory === "Manager" ? 1 : 0}/>        
            </TeamMembersEmojiSet>
            
            <TeamMembersHover>
                {members.map((g, idx) => (
                    <div
                      key={idx}
                      id={g.id}
                      name={g.name}
                    >
                      <MemberBoxStyle 
                        category={g.category}
                        opacity={hoveredCategory === g.category ? 1 : 0}>
                        <MemberBoxTopStyle>
                          <span>{g.name}</span>
                          <BorderStyle />
                          <span>{g.position}</span>     
                        </MemberBoxTopStyle>
                        <MemberBoxDetail1Style>
                          {g.detail1.map((x,idx)=>(
                              <span>{"•"+" "+x}</span>
                          ))}
                        </MemberBoxDetail1Style>
                        <MemberBoxDetail2Style>
                          {g.detail2.map((x,idx)=>(
                              <span>{x}</span>
                          ))}
                        </MemberBoxDetail2Style>                                    
                      </MemberBoxStyle>
                    </div>
                ))}
              </TeamMembersHover>
          </TeamMembersHoverContainer>
        </TeamMembersImgContainer>
        <EmojiExplain />
    </TeamMembersContainer>
  );
};

export default TeamMembers;