import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import SelfGenerate from './SelfGenerate';
import FineTuningImg from './FineTuningImg';
import FineTuningAI from './FineTuningAI';
import PromptEngineering from './PromptEngineering';
import Header from './header/Header';

const ImageTechContainer = styled.div`
height: auto;
`

const ImageTech = () => {

  useEffect(() => {
    // 페이지가 렌더링될 때 맨 위로 스크롤 이동
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <ImageTechContainer>
      <Header />
        <SelfGenerate />
        <FineTuningImg />
        <FineTuningAI />
        <PromptEngineering />
    </ImageTechContainer>
  );
};

export default ImageTech;