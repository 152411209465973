import React, { useEffect, useRef, useState } from 'react';
import styled from "@emotion/styled/macro";
import Introduce from './Introduce';
import BackGroundImg from './BackgroundImg';
import Shortcut from './Shortcut';
import OurService from './OurService';
import AboutUs from './AboutUs';
/* import TechPart from './XslideEx'; */
import History from './History';
import Footer from './Footer';
import IntroduceSecond from './IntroduceSecond';
import Messages from '../Messages/Messages';
import TeamMembers from './teammembers/TeamMembers';
import Header from './header/Header';
import { useSelector } from 'react-redux';
import TechPart from './techpart/TechPart';
import Projects from './projects/Projects';
import Contact from './contact/Contact';
import Recruit from './recruit/Recruit';

const MainContainer = styled.div`
/* overflow-x: hidden; */
  width: 100%;
  height: ${(props) => (props.agreeclick ? '99999px' : '100%')};
background: #141414;
/* position: relative; */
position: relative;
z-index: 0;
overflow-x: hidden;
overflow-y: hidden;
`
const BackGroundImgDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  
  
/*   position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; */
  margin-top: ${(props) => (props.showMargin ? '254px' : '0')};
  transition: opacity , 0.3s ease, transform 0.3s ease;
`;

const MainDiv = styled.div`
display: flex;
flex-direction: column;
`
const Main = () => {
  const [showMarginTop, setShowMarginTop] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);
  const {agreeclick} = useSelector((state) => state.messagesinfo);
  const [scrollRef,setScrollRef] = useState('Introduce');

  //header navigate
  const joysRef = useRef();
  const aiRef = useRef();
  const techPartRef = useRef();
  const aboutUsRef = useRef();
  const projectsRef = useRef();
  const teamMembersRef = useRef();
  const contactRef = useRef();

  const [headerState, setHeaderState] = "" ;

  useEffect(() => {
    const firstIntroduceElement = document.getElementById('first-introduce');
    const handleScroll = () => {
      if (!firstIntroduceElement) return;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      /* console.log(scrollTop) */
      setScrollTop(scrollTop);
      if(scrollTop < 100){
        setShowMarginTop(true)
      }else{
        setShowMarginTop(false)
      }
      if (scrollTop >= joysRef.current.offsetTop && scrollTop < aiRef.current.offsetTop) {
        setScrollRef('Introduce');
      } else if (scrollTop >= aiRef.current.offsetTop && scrollTop < techPartRef.current.offsetTop) {
        setScrollRef('Messages');
      } else if (scrollTop >= techPartRef.current.offsetTop && scrollTop < aboutUsRef.current.offsetTop) {
        setScrollRef('TechPart');
      } else if (scrollTop >= aboutUsRef.current.offsetTop && scrollTop < projectsRef.current.offsetTop) {
        setScrollRef('AboutUs');
      } else if (scrollTop >= projectsRef.current.offsetTop && scrollTop < teamMembersRef.current.offsetTop) {
        setScrollRef('Projects');
      } else if (scrollTop >= teamMembersRef.current.offsetTop && scrollTop < contactRef.current.offsetTop) {
        setScrollRef('TeamMembers');
      } else if (scrollTop >= contactRef.current.offsetTop) {
        setScrollRef('Contact');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  // console.log(scrollRef)

  return (
    <MainContainer agreeclick = {agreeclick}>
      <Header scrollTop = {scrollTop} scrollRef={scrollRef} joysRef={joysRef} aiRef={aiRef} techPartRef={techPartRef} aboutUsRef={aboutUsRef} projectsRef={projectsRef} teamMembersRef={teamMembersRef} contactRef={contactRef}/>
      <BackGroundImgDiv showMargin={showMarginTop}>
        <BackGroundImg scrollTop = {scrollTop}/>
      </BackGroundImgDiv>
      <MainDiv id="first-introduce" ref={joysRef}>
        <Introduce id ="Introduce" scrollTop = {scrollTop} />
        {/* <Shortcut /> */}
        <IntroduceSecond id ="IntroduceSecond"/>
        {/* <OurService /> */}
        <Messages id="Messages" aiRef={aiRef}/>
        <TechPart id="TechPart" techPartRef={techPartRef}/>
        <AboutUs aboutUsRef={aboutUsRef}/>
        <History />
        <Projects scrollTop = {scrollTop} projectsRef={projectsRef} contactRef={contactRef}/>
        {/* <TechPart /> */}
        <TeamMembers teamMembersRef={teamMembersRef}/>
        <Contact contactRef={contactRef}/>
        <Recruit />
        <Footer />
      </MainDiv>
    </MainContainer>
  );
};

export default Main;