import React, { useState } from 'react';
import styled from "@emotion/styled/macro";
import { Prompt_Engineering_Main_Img, Prompt_Engineering_Sub_Img } from '../../style/img';

const PromptEngineeringContainer = styled.div`
width: auto;
height: 1054px;
background-color: #141414;
`
const PromptEngineeringFirstSpan = styled.span`
margin-top: 90px;
margin-left: 80px;
color: #E7E7E7;
font-family: 'Pretendard';
font-size: 30px;
font-style: normal;
font-weight: 900;
line-height: 135%; /* 40.5px */
letter-spacing: -0.9px;
`
const PromptEngineeringDivStyle = styled.div`
margin-top: 94px;
gap:47px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const PromptEngineeringSpanDivStyle = styled.div`
display: flex;
`

const PromptEngineering = () => {

  return (
    <PromptEngineeringContainer>
        <PromptEngineeringSpanDivStyle>
            <PromptEngineeringFirstSpan>Prompt Engineering</PromptEngineeringFirstSpan>
        </PromptEngineeringSpanDivStyle>
        
        <PromptEngineeringDivStyle>
            <img src = {Prompt_Engineering_Main_Img} />
            <img src = {Prompt_Engineering_Sub_Img} />
        </PromptEngineeringDivStyle>
    </PromptEngineeringContainer>
  );
};

export default PromptEngineering;