import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4"

const RouteChangeTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        // localhost로 실행시 추적 방지
        if (!window.location.href.includes("localhost")) {
          if (process.env.REACT_APP_GA_ID) {
            ReactGA.initialize(process.env.REACT_APP_GA_ID);
          }
        }
        setInitialized(true);
      }, []);
      
      // 추적 코드
      useEffect(() => {
        if (initialized) {
            // console.log(location.pathname + location.search)
            ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
          // ReactGA.pageview(location.pathname + location.search); 더이상 pageview를 지원하지 않는다.
        }
      }, [initialized, location]);
}

export default RouteChangeTracker;