import React, { useState } from 'react';
import styled from "@emotion/styled/macro";
import { PatentSticker, SelfGenerate_Main_Img } from '../../style/img';

const SelfGenerateContainer = styled.div`
width: auto;
height: 864px;
/* background: #141414; */
display: flex;
justify-content: center;
align-items: center;

background-color: #141414;
`
const SelfGenerateSpanDivStyle = styled.div`
display: flex;
flex-direction: column;
gap:10px;

position: absolute;
top:70px;
left: 80px;
`
const SelfGenerateFirstSpan = styled.span`
color: #E7E7E7;
font-family: 'Pretendard';
font-size: 30px;
font-style: normal;
font-weight: 900;
line-height: 135%; /* 40.5px */
letter-spacing: -0.9px;
`
const SelfGenerateSecondSpan = styled.span`
color: #E9E9E9;
text-align: center;
font-family: 'Pretendard';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 21.6px */
letter-spacing: -0.8px;
`
const StickerStyle = styled.img`
position: absolute;
top: 23px;
right: 32.26px;
`
const MainImgContainer = styled.div`
`

const SelfGenerate = () => {

  return (
    <SelfGenerateContainer>
        <SelfGenerateSpanDivStyle>
            <SelfGenerateFirstSpan>
                자체 이미지 생성 AI
            </SelfGenerateFirstSpan>
            <SelfGenerateSecondSpan>
                Image generation AI fine-tuning model training
            </SelfGenerateSecondSpan>
        </SelfGenerateSpanDivStyle>
        <StickerStyle src = {PatentSticker}/>
        <MainImgContainer>
            <img src={SelfGenerate_Main_Img} />
        </MainImgContainer>
    </SelfGenerateContainer>
  );
};

export default SelfGenerate;