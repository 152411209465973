import React, { useEffect, useRef, useState } from 'react';
import styled from "@emotion/styled/macro";

const FirstContainer = styled.div`

`

const FirstSpan1 = styled.span`
position: fixed;
/* transform: translateY(${props => (props.offset ? `${props.offset}px` : '0')});
transition: transform 0.3s ease; */

top: 400px;
font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: -0.24px;
z-index: -1;
`
const FirstSpan2 = styled.span`
position: relative;
//margin-top:440px;

position : ${(props) => (props.showMargin ? 'relative' : 'fixed')};
margin-top: ${(props) => (props.showMargin ? '1000px' : '440px')};

/* transform: translateY(${props => (props.offset ? `${props.offset}px` : '0')});
transition: transform 0.3s ease; */
//top: 650px;

//transition: opacity , 0.3s ease, transform 0.3s ease;

font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: normal;
letter-spacing: -0.24px;
z-index: -1;
`
const FirstSpan3 = styled.span`
position: relative;
//top: 1100px;
/* transform: translateY(${props => (props.offset ? `${props.offset}px` : '0')});
transition: transform 0.3s ease; */
position : ${(props) => (props.showMargin ? 'relative' : 'fixed')};

margin-top: ${(props) => (props.showMargin ? '1440px' : '540px')};
//transition: opacity , 0.3s ease, transform 0.3s ease;

color: #FFF;
text-align: center;
font-family: Pretendard_Blod;
font-size: 54px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.54px;
z-index: -1;
`
const Sapn5Div = styled.div`
width: 100vw;
height: 1500px;
color: #D9D9E1;
text-align: center;
font-family: 'Pretendard';
font-size: 50px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -3.5px;
`
const OffsetSpanDiv = styled.div`
display: flex;
align-items: center;
flex-direction: column;
color: #FFF;
z-index: 0;

opacity: ${(props) => (props.opacity)};
`

const Introduce = () => {
  const [showMarginTop, setShowMarginTop] = useState(true);
  const [showMarginTop2, setShowMarginTop2] = useState(true);
  const [scrollTop, setScrollTop] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const requestRef = useRef();

  /* const [offset1, setOffset1] = useState(0);
  const [offset2, setOffset2] = useState(0);
  const [offset3, setOffset3] = useState(0); */
  
  
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollTop);
    if (scrollTop <= 500) {
      setShowMarginTop(true);
    } else {
      setShowMarginTop(false);
    }
    if (scrollTop <= 800) {
      setShowMarginTop2(true);
    } else {
      setShowMarginTop2(false);
    }
    if (scrollTop >= 2200) {
      setOpacity(0);
    } else {
      setOpacity(1);
    }
  };

  const animate = () => {
    handleScroll();
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  return (
    <div>
    <FirstContainer>

      <OffsetSpanDiv opacity = {opacity} >
        <FirstSpan1 >어디서든</FirstSpan1>
        <FirstSpan2 showMargin = {showMarginTop} >당신의</FirstSpan2>
        <FirstSpan3 showMargin = {showMarginTop2} >즐거움을 최대로</FirstSpan3>
      </OffsetSpanDiv>

        {/* <FirstSpan4>다이얼로그식 이미지 생성 서비스 플랫폼</FirstSpan4> */}
        <Sapn5Div>
{/*           <Span5_1>with</Span5_1>
          <svg width="175" height="53" viewBox="0 0 175 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.4662 0.696024H37.0883V35.5966C37.0718 38.911 36.2349 41.8277 34.5777 44.3466C32.937 46.849 30.6667 48.8045 27.7666 50.2131C24.8831 51.6051 21.5604 52.3011 17.7985 52.3011C14.5339 52.3011 11.5509 51.7377 8.84969 50.6108C6.14845 49.4673 3.9941 47.6776 2.38662 45.2415C0.779136 42.7888 -0.0163183 39.607 0.000253652 35.696H13.8213C13.871 36.9721 14.0864 38.0492 14.4676 38.9276C14.8653 39.8059 15.4122 40.4688 16.1082 40.9162C16.8208 41.3471 17.6825 41.5625 18.6934 41.5625C19.7209 41.5625 20.5826 41.3388 21.2787 40.8913C21.9913 40.4439 22.5298 39.781 22.8944 38.9027C23.259 38.0078 23.4496 36.9058 23.4662 35.5966V0.696024Z" fill="#D9D9E1"/>
            <path d="M90.0566 26.1506C90.0566 31.8182 88.9545 36.5992 86.7505 40.4936C84.5464 44.3714 81.5717 47.313 77.8265 49.3182C74.0812 51.3068 69.9051 52.3011 65.298 52.3011C60.6579 52.3011 56.4652 51.2985 52.7199 49.2933C48.9912 47.2715 46.0248 44.3217 43.8208 40.4439C41.6333 36.5495 40.5395 31.785 40.5395 26.1506C40.5395 20.483 41.6333 15.7102 43.8208 11.8324C46.0248 7.93797 48.9912 4.99645 52.7199 3.00781C56.4652 1.0026 60.6579 0 65.298 0C69.9051 0 74.0812 1.0026 77.8265 3.00781C81.5717 4.99645 84.5464 7.93797 86.7505 11.8324C88.9545 15.7102 90.0566 20.483 90.0566 26.1506ZM75.8378 26.1506C75.8378 23.1013 75.4318 20.5327 74.6198 18.4446C73.8243 16.34 72.6394 14.7491 71.0651 13.6719C69.5073 12.5781 67.585 12.0312 65.298 12.0312C63.0111 12.0312 61.0805 12.5781 59.5061 13.6719C57.9484 14.7491 56.7635 16.34 55.9515 18.4446C55.156 20.5327 54.7583 23.1013 54.7583 26.1506C54.7583 29.1998 55.156 31.7768 55.9515 33.8814C56.7635 35.9695 57.9484 37.5604 59.5061 38.6541C61.0805 39.7313 63.0111 40.2699 65.298 40.2699C67.585 40.2699 69.5073 39.7313 71.0651 38.6541C72.6394 37.5604 73.8243 35.9695 74.6198 33.8814C75.4318 31.7768 75.8378 29.1998 75.8378 26.1506Z" fill="#D9D9E1"/>
            <path d="M84.9085 0.696024H100.32L110.264 21.3778H110.661L120.605 0.696024H136.016L117.323 35.5966V51.6051H103.602V35.5966L84.9085 0.696024Z" fill="#D9D9E1"/>
            <path d="M161.065 16.6051C160.933 14.9479 160.311 13.6553 159.201 12.7273C158.107 11.7992 156.442 11.3352 154.204 11.3352C152.779 11.3352 151.611 11.5092 150.699 11.8572C149.805 12.1887 149.142 12.6444 148.711 13.2244C148.28 13.8045 148.056 14.4673 148.04 15.2131C148.006 15.8262 148.114 16.3814 148.363 16.8786C148.628 17.3591 149.042 17.7983 149.606 18.196C150.169 18.5772 150.89 18.9252 151.768 19.2401C152.647 19.5549 153.691 19.8366 154.9 20.0852L159.077 20.9801C161.894 21.5767 164.305 22.3639 166.31 23.3416C168.315 24.3194 169.956 25.4711 171.232 26.7969C172.508 28.1061 173.444 29.581 174.041 31.2216C174.654 32.8622 174.969 34.652 174.986 36.5909C174.969 39.9384 174.132 42.7723 172.475 45.0923C170.818 47.4124 168.448 49.1773 165.366 50.3871C162.3 51.5968 158.613 52.2017 154.304 52.2017C149.879 52.2017 146.018 51.5471 142.72 50.2379C139.439 48.9287 136.887 46.9153 135.064 44.1974C133.257 41.4631 132.346 37.9664 132.329 33.7074H145.454C145.537 35.2652 145.927 36.5743 146.623 37.6349C147.319 38.6955 148.296 39.4993 149.556 40.0462C150.832 40.593 152.348 40.8665 154.105 40.8665C155.58 40.8665 156.814 40.6842 157.809 40.3196C158.803 39.955 159.557 39.4496 160.071 38.8033C160.585 38.157 160.85 37.4195 160.866 36.5909C160.85 35.812 160.593 35.1326 160.096 34.5526C159.615 33.956 158.82 33.4257 157.709 32.9616C156.599 32.4811 155.099 32.0336 153.21 31.6193L148.139 30.5256C143.631 29.5478 140.077 27.9155 137.475 25.6286C134.89 23.325 133.605 20.1847 133.622 16.2074C133.605 12.9759 134.467 10.1503 136.207 7.73083C137.964 5.29475 140.392 3.39725 143.491 2.03835C146.606 0.679452 150.177 0 154.204 0C158.314 0 161.869 0.687738 164.868 2.06321C167.868 3.43869 170.18 5.37761 171.804 7.87997C173.444 10.3658 174.273 13.2741 174.29 16.6051H161.065Z" fill="#D9D9E1"/>
          </svg> */}

        </Sapn5Div>
    </FirstContainer>
    </div>
  );
};

export default Introduce;