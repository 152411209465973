import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { Main_Background_2, Main_Background_Img, } from '../../style/img';


const BackGroundImgContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;

`
const StyledImg_JOYS = styled.img`
z-index: -1;
`;

const BackGroundImg = ({scrollTop}) => {
  const [backgroundImg,setBackgroundImg] = useState(false);

  useEffect(() => {
    let timeoutId;

    // Create a function to update the backgroundImg state after a delay
    const updateBackgroundImg = () => {
      if (scrollTop > 2800) {
        setBackgroundImg(true);
      } else {
        setBackgroundImg(false);
      }
    };

    // Clear the previous timeout
    clearTimeout(timeoutId);

    // Set a new timeout
    timeoutId = setTimeout(updateBackgroundImg, 0); // Adjust the delay time as needed

    // Cleanup: Clear the timeout when the component unmounts or when scrollTop changes
    return () => clearTimeout(timeoutId);
  }, [scrollTop]);

  return (
    <BackGroundImgContainer>
        <StyledImg_JOYS src={backgroundImg ? Main_Background_2 : Main_Background_Img} alt=""/>
    </BackGroundImgContainer>
  );
};

export default BackGroundImg ;