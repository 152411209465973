import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { useDispatch, useSelector } from 'react-redux';
import { HeaderStarIcon, Header_JOYS_Logo } from '../../../style/img';
import { useNavigate } from 'react-router-dom';

const StickyHeaderContainer = styled.div`
position: fixed;
top: 0;
display: flex;
width: 99vw;
height: 49px;
align-items: center;
//
border-top: 1px solid rgba(255, 255, 255, 0.60);
border-bottom: 1px solid rgba(255, 255, 255, 0.60);
border-left: 1px solid rgba(255, 255, 255, 0.60);
background: rgba(255, 255, 255, 0.01);
backdrop-filter: blur(15px);
z-index: 1000;
&:hover{
    cursor:pointer;
}
`
const FirstHeaderBoxStyle = styled.div`
display: flex;
align-items: center;
margin-left: 16px;
height: 49px;
flex-grow: 3;
border-right: 1px solid rgba(255, 255, 255, 0.60);
`

const HeaderBoxStyle = styled.div`
height: 49px;
display: flex;
flex-grow: ${(props) => (props.selected ? '9' : '1')};
justify-content: ${(props) => (props.selected ? 'left' : 'center')};
margin-left: ${(props) => (props.selected ? '28px' : '')};
align-items: center;
gap: 10px;
border-right: 1px solid rgba(255, 255, 255, 0.60);
`
const StarImg = styled.img`
display: ${(props) => (props.selected ? 'flex' : 'none')};
`
const HeaderSpanStyle = styled.span`
color: ${(props) => (props.selected ? '#FFFFFF' : 'rgba(255, 255, 255, 0.60)')};
text-align: center;
text-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.40);
font-family: 'Pretendard';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.98px;
`

const categories = [
    { name: "자체 이미지 생성 AI" },
    { name: "Fine - tuning AI Model Merging" },
    { name: "Prompt Engineering" },
  ];

const Header = () => {
    const [category,setCategory] = useState("자체 이미지 생성 AI");
    const navi = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          console.log(scrollTop)
          if (scrollTop >= 0 && scrollTop <= 1684) {
            setCategory("자체 이미지 생성 AI");
          } else if (scrollTop >= 1685 && scrollTop <= 3329) {
            setCategory("Fine - tuning AI Model Merging");
          } else {
            setCategory("Prompt Engineering");
          }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);

    const { headerinfo } = useSelector((state) => state.headerinfo);
    const onCategoryClick = (name) => {
        //dispatch(setheaderinfo(name));
        switch (name) {
            case "JOYS":
                console.log('Joys')
                navi("/");
            break;

            case "자체 이미지 생성 AI":
                window.scrollTo({top:0});
            break;

            case "Fine - tuning AI Model Merging":
                window.scrollTo({top:1685});
            break;

            case "Prompt Engineering":
                window.scrollTo({top:3330});
            break;

            default:
                break;
        };
        
    };

    useEffect(()=>{
        console.log(headerinfo)
    },[headerinfo])

  return (
    <StickyHeaderContainer>
        <FirstHeaderBoxStyle
        onClick={() => {
            onCategoryClick("JOYS");
            }}
        >
            <HeaderSpanStyle>
                <img src={Header_JOYS_Logo}/>
            </HeaderSpanStyle>
        </FirstHeaderBoxStyle>

        {categories &&
            categories.map((c, idx) => (
                <HeaderBoxStyle
                    key={idx}
                    name={c.name}
                    onClick={() => {
                    onCategoryClick(c.name);
                    }}
                    selected={category === c.name}
                >
                    <StarImg
                        src={HeaderStarIcon}
                        selected={category === c.name}/>
                    <HeaderSpanStyle
                        selected={category === c.name}
                    >
                        {c.name}
                    </HeaderSpanStyle>
                </HeaderBoxStyle>
                ))}
    </StickyHeaderContainer>
  );
};

export default Header;