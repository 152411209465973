import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { Project_Storist_1, Project_Storist_1_BG_Webp, Project_Storist_2, Project_Storist_3_1, Project_Storist_3_2, Project_Storist_3_3, Project_Storist_3_4, Project_Storist_3_5, Project_Storist_4, Star_StudioDopple, Storist_Full_Logo, Storist_Icon } from '../../style/img';
import { useXScroll } from '../../module/xscroll';

const StoristContainer = styled.div`
height: auto;
overflow-x: hidden;
max-width: 2000px;
`
const FirstDivStyle = styled.div`
height: 948px;
background-color: #DEE2E6;
`
const TopDivStyle = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 97px ;
gap: 30%;
`

const LeftSpanDivStyle = styled.div`
display: flex;
flex-direction: column;
z-index: 1;
`
const MainSpanStyle = styled.span`
color: #262626;
font-family: Pretendard;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 54px */
letter-spacing: -1.6px;
`
const SubSpanStyle = styled.span`
color: #262626;
font-family: Pretendard;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 27px */
letter-spacing: -0.8px;
`

const RightSpanDivStyle = styled.div`
height: 34px;
display: flex;
justify-content: center;
align-items: center;
padding: 0px 20px;
border-radius: 40px;
border: 1px solid #262626;

z-index: 1;
`
const RightSpanStyle = styled.span`
color: #262626;
font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 109.867%; /* 17.579px */
letter-spacing: -0.8px;
`

const MainDivStyle= styled.div`
display: flex;
justify-content: center;
background-image: url(${Project_Storist_1_BG_Webp});
height: 1014px;
`
const MainImgStyle = styled.img`
margin-left: 54%;
margin-right: 46%;
width: 90%;
height: 90%;
z-index: 0;
transform: translateY(${props => (props.offset ? `${props.offset}px` : '-100px')});
transition: transform 0.3s ease;
`
const IconImgStyle = styled.img`
display: flex;
justify-content: center;
align-items: center;
position: absolute;
margin-left: 15%;
margin-top: -330px;
z-index: 3;
`

const SecondDivStyle = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
height: 377px;
background-color: #EFF2F4;

gap:18%;
z-index: 2;
`
const SecondSpanStyle = styled.span`
color: #141414;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 18.9px */
letter-spacing: -0.7px;
width: 589px;
white-space:clip;
`

const ThirdDivStyle = styled.div`
height: 942px;
background: #7A8086;
z-index: 1;
position: relative;
`
const ImgDivStyle = styled.div`
z-index: 1;
display: flex;

`
const ImgLeftDivStyle = styled.div`
width: 197px;
height: 197px;
background-image: ${(props) => `url(${props.background})`};
background-repeat: no-repeat;
display: flex;
/* justify-content: center; */
align-items: center;
flex-direction: column;
margin-left: 15%;
margin-top: 5%;
`
const StudioDoppleStyle = styled.img`
margin-top: 18%;
margin-right: 3%;
`

const ImgRightDivStlye = styled.div`
z-index: 1;
margin-left: -10%;
`
const ThirdSpanDivStyle = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin-top: -100px;
gap: 30%;
`
const ThirdLeftSpanStyle = styled.span`
color: #EFF2F4;
font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 32.4px */
letter-spacing: -0.48px;
`
const ThirdRightSpanStyle = styled.span`
color: #D8D8D8;
font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 21.6px */
letter-spacing: -0.8px;
`

//Fourth
const FourthDivStlye = styled.div`
display: flex;
background-color: #7A8086;
`
const FourthEmptyDiv = styled.div`
height: 376px;
width: 1500px;
`
const FourthImgDivStlye = styled.div`
display: flex;
overflow-x: scroll;
gap:21px;
padding-left: 10%;

&::-webkit-scrollbar {
  display: none;
  };
  
&:hover {
  cursor: grab;
};
&:active{
  cursor: grabbing;
};
`

//FifthDivStlye
const FifthDivStlye = styled.div`
display: flex;
align-items: center;

overflow-y: hidden;
height: 706px;
background-repeat: no-repeat;
display: flex;
background-image: url(${Project_Storist_4});
background-size: cover;
`
const FifthSpanDiv = styled.div`
margin-left: 10%;
display: flex;
flex-direction: column;
gap: 173px;
`
const FifthTopSpan = styled.span`
color: #262626;
font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 32.4px */
letter-spacing: -0.48px;
`
const FifthBottomSpanDiv = styled.div`
display: flex;
width: 408px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
//
border-radius: 35px;
border: 1px solid #44E46E;

&:hover{
  cursor: pointer;
  background: #141414;
}
`
const FifthBottomSpan = styled.span`
color: #44E46E;
font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 32.4px */
letter-spacing: -0.96px;
`
const Storist = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const { scrollRef, onDragStart, isDrag, onThrottleDragMove, onDragEnd } = useXScroll();

  useEffect(() => {
    const firstIntroduceElement = document.getElementById('first-introduce');
    const handleScroll = () => {
      if (!firstIntroduceElement) return;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      console.log(scrollTop)
      if(scrollTop<700){
        setScrollTop(scrollTop);
      }      
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const offset = Math.max(0, scrollTop - 100);
 /*  console.log(offset) */
 useEffect(() => {
  // 페이지가 렌더링될 때 맨 위로 스크롤 이동
  window.scrollTo(0, 0);
}, []);
  return (
    <StoristContainer>
      <FirstDivStyle id = "first-introduce">
      <br />
        <TopDivStyle>
          <LeftSpanDivStyle>
            <MainSpanStyle>
              Storist
            </MainSpanStyle>
            <SubSpanStyle>
              이미지 생성 AI를 이용하여 스토리보드 제작을 손쉽게!
            </SubSpanStyle>
          </LeftSpanDivStyle>

          <RightSpanDivStyle>
            <RightSpanStyle>
              MVP 출시완료
            </RightSpanStyle>
          </RightSpanDivStyle>
        </TopDivStyle>
        <MainDivStyle>
          <MainImgStyle src = {Project_Storist_1} offset={offset}/>
        </MainDivStyle>
        <IconImgStyle src={Storist_Icon} />
      </FirstDivStyle>

      <SecondDivStyle>
        <img src = {Storist_Full_Logo} />
        <SecondSpanStyle>
          스토리스트 AI는 영상 촬영을 위해 만드는 스토리보드 제작을 간편하게 도와주는 서비스입니다.
          이미지를 찾는 번거로움 없이, 원하는 이미지를 스토리스트 AI에 입력하면 바로 해당 이미지를 생성하고 스토
          리보드 형태의 Pdf 파일로 변환하여 내보낼 수 있어요. 이제 레퍼런스 이미지 검색으로 시간과 비용을 낭비할
          필요가 없어요. 스토리스트 AI와 함께라면 누구나 손쉽게 멋진 아이디어를 시각화하고, 창작의 즐거움을 느낄
          수 있답니다.
        </SecondSpanStyle>
      </SecondDivStyle>

      <ThirdDivStyle>
        <br />
        <ImgDivStyle>
          <ImgLeftDivStyle >
            <StudioDoppleStyle src={Star_StudioDopple} />
          </ImgLeftDivStyle>
          <ImgRightDivStlye>
            <img src={Project_Storist_2} />
          </ImgRightDivStlye>
        </ImgDivStyle>
        <ThirdSpanDivStyle>
          <ThirdLeftSpanStyle>
            스토리스트에 상황만 입력하면
            <br />
            원하는 이미지를 AI가 생성합니다.
          </ThirdLeftSpanStyle>
          <ThirdRightSpanStyle>
            실제 스토리스트로 생성한 이미지 입니다.
          </ThirdRightSpanStyle>
        </ThirdSpanDivStyle>
      </ThirdDivStyle>

      <FourthDivStlye>
        <FourthImgDivStlye
        ref={scrollRef}
        onMouseDown={onDragStart} // 마우스 왼쪽 누르고 있는 상태
        onMouseMove={isDrag ? onThrottleDragMove : null} // 마우스 움직이는 상태 (isDrag 가 true 일때만)
        onMouseUp={onDragEnd} // 마우스 왼쪽 뗀 상태
        onMouseLeave={onDragEnd} // dom에서 마우스가 벗어난지 체크
        >
          <FourthEmptyDiv>

          </FourthEmptyDiv>
          <img src={Project_Storist_3_1}/>
          <img src={Project_Storist_3_2}/>
          <img src={Project_Storist_3_3}/>
          <img src={Project_Storist_3_4}/>
          <img src={Project_Storist_3_5}/>
        </FourthImgDivStlye>
      </FourthDivStlye>
      
      <FifthDivStlye>
        <FifthSpanDiv>
          <div>
            <FifthTopSpan>
            준비된 탬플릿에 적용해 쉽게
            <br />
            스토리보드를 만들고 PDF로 변환하세요.
            </FifthTopSpan>
          </div>
          
          <FifthBottomSpanDiv
            onClick={() => window.open("https://storist.ai/")}>
            <FifthBottomSpan>스토리스트 바로가기</FifthBottomSpan>  
          </FifthBottomSpanDiv>
        </FifthSpanDiv>        
      </FifthDivStlye>

    </StoristContainer>
  );
};

export default Storist;