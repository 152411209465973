import React, { useState } from 'react';
import styled from "@emotion/styled/macro";

const SecondContainer = styled.div`
width: 100%;
margin-top: 305px;

display: flex;
justify-content: center;
align-items: center;
//
z-index: 99;

background: rgba(255, 255, 255, 0.10);
backdrop-filter: blur(100px);;
white-space:nowrap;

border-top: 1px solid rgba(255, 255, 255, 0.60);
`

const IntroduceSecondDiv = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 113.5px 0px 114.5px 0px;
`

const SpanTop = styled.span`
color: #B0B0B0;
font-family: Pretendard_Light;
font-size: 50px;
font-style: normal;
font-weight: 300;
line-height: 100px; /* 200% */
letter-spacing: -1px;
text-decoration: line-through 2px;
`
const SpanMiddle = styled.span`
color: #FFF;
font-family: Pretendard_Black;
font-size: 70px;
font-style: normal;
font-weight: 900;
line-height: 100px; /* 142.857% */
letter-spacing: -3px;
`
const SpanBottom = styled.span`
color: #FFF;
font-family: 'Pretendard';
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: 135%; /* 32.4px */
letter-spacing: -0.96px;
`

const IntroduceSecond = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);

    const onCategoryClick = (name) => {
        setSelectedCategory(name);
        };
  return (
    <SecondContainer>
        <IntroduceSecondDiv>
            <SpanTop>No Prompt</SpanTop>
            <SpanMiddle>Just talk to me</SpanMiddle>
            <SpanBottom>다이얼로그식 이미지 생성 AI 서비스</SpanBottom>
        </IntroduceSecondDiv>
    </SecondContainer>
  );
};

export default IntroduceSecond;