import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { 
  NoiseFilter, 
  PlumAiAiImg, 
  PlumAi_Logo, 
  PlumChatBoxImg, 
  Plum_AI_Explanation, 
  Plum_AI_Explanation2, 
  Plum_Ai_Create_Img, 
  Plum_Ai_Site, 
  Plum_BG_Apple_1, 
  Plum_BG_Apple_2, 
  Plum_Small_Icon, } from '../../style/img';

// import { useXScroll } from '../../module/xscroll';

const PlumAi = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const firstIntroduceElement = document.getElementById('first-introduce');
    const handleScroll = () => {
      if (!firstIntroduceElement) return;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      console.log(scrollTop)
      if(scrollTop<1000){
        setScrollTop(scrollTop);
      }      
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const offset = Math.max(0, scrollTop - 200);
 /*  console.log(offset) */
 useEffect(() => {
  // 페이지가 렌더링될 때 맨 위로 스크롤 이동
  window.scrollTo(0, 0);
}, []);
  return (
    <PlumAiContainer>
      <FirstDivStyle id = "first-introduce">
        <MainBackgroundImg_Apple1Container />
        <MainBackgroundImg_Apple2Container />
        <br />
        <TopDivStyle>
          <LeftSpanDivStyle>
            <MainSpanStyle>
              Plum Ai
            </MainSpanStyle>
            <SubSpanStyle>
            챗봇과 대화형식으로 이미지 생성 및 수정
            </SubSpanStyle>
          </LeftSpanDivStyle>
          <RightSpanDivStyle>
            <RightSpanStyle>
              MVP 출시완료
            </RightSpanStyle>
          </RightSpanDivStyle>
        </TopDivStyle>
        <MainDivStyle>
          <MainImgStyle src = {Plum_Ai_Site} offset={offset}/>
        </MainDivStyle>
        {/* <IconImgStyle src={Plum_Small_Icon} /> */}
        {/* background image set */}
        <MainDivNoiseFilterContainer />
      </FirstDivStyle>

      <SecondDivStyle>
        <IconImgStyle src={Plum_Small_Icon} />
        <AvadotLogoImg src = {PlumAi_Logo} />
        <SecondSpanStyle>
          Plum Ai.는 이미지 생성 챗봇 입니다. 대화형식으로 이미지를 생성할 뿐만 아니라 수정도 가능합니다. 배경 및 의상 바꾸기, 물건 추가하기 등  프롬프트 전문가가 아니여도, 미리 준비된 프리셋으로 고퀄리티의 이미지를 
          생성하고 수정할 수 있어요.
        </SecondSpanStyle>
      </SecondDivStyle>

      <ThirdDivStyle>
        <div className='Plum-Third-Left'>
          <PlumAiCreateImgStyle src={Plum_Ai_Create_Img} alt="aa"/>
        </div>
        <div className='Plum-Third-Right'>
          <div className='Plum-Third-Right-Title'>
            <span className='Plum-Third-Right-FirstSpan'>이미지 학습없이</span>
            <span className='Plum-Third-Right-SecondSpan'>5초안에 생성</span>
          </div>
          <div>
            <img src={PlumAiAiImg} alt="Joys-ai-Plum-Ai-Img"/>
          </div>
        </div>
      </ThirdDivStyle>
      
      <FourthDivStyle>
        <FourthTitleContainer>
          <FourthTitleTopStyle>
            생성한 이미지를 마음에 들때까지
          </FourthTitleTopStyle>
          <FourthTitleBottomStyle>
            플럼과 수정하기
          </FourthTitleBottomStyle>
        </FourthTitleContainer>
        <FourthMainContainer>
          <FourthMainLeftContainer>
            <FourthMainLeftTopContainer><img src={Plum_AI_Explanation} alt="Plumai_BlondeWoman"/></FourthMainLeftTopContainer>
          </FourthMainLeftContainer>
          <FourthMainRightContainer>            
          </FourthMainRightContainer>
        </FourthMainContainer>
      </FourthDivStyle>

      <FifthDivStyle>
        <FifthTitleContainer>
          <div className='Fifth-Top-Title'>다양한 스타일의</div>
          <div className='Fifth-Bottom-Title'>이미지 생성 가능</div>
        </FifthTitleContainer>
        <FifthImageContainer>
          <img src={Plum_AI_Explanation2} alt="Plumai_BlondeWoman"/>
        </FifthImageContainer>
      </FifthDivStyle>
    </PlumAiContainer>
  );
};

export default PlumAi;

const FifthDivStyle = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: #EFEFFD;
`
const FifthTitleContainer = styled.div`
display: flex;
flex-direction: column;

margin-top: 100px;
margin-left: -400px;
font-family: Pretendard;
font-size: 60px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 81px */
letter-spacing: -3px;
  .Fifth-Top-Title{
    color: #4345DA;
  }
  .Fifth-Bottom-Title{
    color: #141414;
  }
`
const FifthImageContainer = styled.div`
display: flex;
margin-left: 400px;
`

const MainImgStyle = styled.img`
border-radius: 23px;
width: 50vw;
@media (min-width: 1921px) {
  width: 30vw;
}
z-index: 0;
transform: translateY(${props => (props.offset ? `${props.offset}px` : '')});
transition: transform 0.1s ease;
`

const PlumAiContainer = styled.div`
width: 100vw;
height: auto;
overflow-x: hidden;
overflow: hidden;
`
const FirstDivStyle = styled.div`
/* width: 100vw;
height: 900px; */
width: 100vw;
overflow-x: hidden;
overflow-y: hidden;
overflow: hidden;
position: relative;
`
const TopDivStyle = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 97px ;
gap: 30%;
`

const LeftSpanDivStyle = styled.div`
display: flex;
flex-direction: column;
z-index: 1;
`
const MainSpanStyle = styled.span`
color: var(--text, #262626);
font-family: Pretendard;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 54px */
letter-spacing: -1.6px;
`
const SubSpanStyle = styled.span`
color: #262626;
font-family: Pretendard;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 27px */
letter-spacing: -0.8px;
`

const RightSpanDivStyle = styled.div`
display: inline-flex;
padding: 8px 20px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 40px;
border: 1px solid #262626;


z-index: 1;
`
const RightSpanStyle = styled.span`
color: #262626;
font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 109.867%; /* 17.579px */
letter-spacing: -0.48px;
`

const MainDivStyle= styled.div`
overflow-y: hidden;
overflow-x: hidden;
width: 100vw;
height: 140vh;
max-height: 1400px;
display: flex;
justify-content: center;
align-items: center;
background-color: #E9D8EA;
background-size: cover;
margin-top: -200px;
`
const MainDivNoiseFilterContainer = styled.div`
width: 100vw;
height: 140vh;
position: absolute;
top: 0px;
left: 0px;
background-image: url(${NoiseFilter});
opacity: 0.5;

`
const MainBackgroundImg_Apple1Container = styled.div`
width: 715px;
height: 752px;
position: absolute;
top: 0px;
left: -10vw;
background-image: url(${Plum_BG_Apple_1});
opacity: 1;
overflow: hidden;

`
const MainBackgroundImg_Apple2Container = styled.div`
width: 1075px;
height: 989px;
position: absolute;
top: 30%;
left: 40vw;
background-image: url(${Plum_BG_Apple_2});
opacity: 1;
`

const IconImgStyle = styled.img`
display: flex; 
position: absolute;
top: -55px;
left: 15%;
z-index: 99;
`

const SecondDivStyle = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
height: 377px;
background: #EFEFFD;
gap:18%;
z-index: 2;
/* overflow-x: hidden; */
`
const AvadotLogoImg = styled.img`
width: 230px;
`
const SecondSpanStyle = styled.span`
color: #141414;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 18.9px */
letter-spacing: -0.7px;
//
width: 589px;
white-space:clip;
`

const ThirdDivStyle = styled.div`
width: 100%;
min-height: 100vh;
padding: 100px 200px 300px 0;
/* height: 150vh; */
background: #EFEFFD;
z-index: 1;
position: relative;
overflow-y: hidden;
overflow-x: hidden;
display: flex;
/* background-color: red; */
justify-content: center;
align-items: center;
  gap: 40px;

.Plum-Third-Left{
  position: absolute;
  z-index: 1;

}
.Plum-Third-Right{
  position: relative;
  top: 50%;
  left: 20%;
  z-index: 2;
  .Plum-Third-Right-Title{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    font-family: Pretendard;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: -3px;
    .Plum-Third-Right-FirstSpan{
      color: #141414;
    }
    .Plum-Third-Right-SecondSpan{
      color: #4345DA;
    }
  }
}
`
const PlumAiCreateImgStyle = styled.img`
width: 100%;
height: 100%;
`

const PlumChatBoxSpan = styled.span`
color: #141414;

/* Body Regular */
font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 21.6px */
letter-spacing: -0.7px;
`
const PlumChatBoxImgStyle = styled.img`
/* position: relative; */
`

//Fourth

const FourthDivStyle = styled.div`
display: flex;
flex-direction: column;
width: 100vw;
/* height: 140vh; */
overflow-y: hidden;
overflow-x: hidden;
`
const FourthTitleContainer =styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 100px;
`
const FourthTitleTopStyle =styled.div`
display: flex;
color: #141414;
text-align: center;
font-family: Pretendard;
font-size: 60px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 81px */
letter-spacing: -3px;
`
const FourthTitleBottomStyle =styled.div`
color: #4345DA;
font-family: Pretendard;
font-size: 60px;
font-style: normal;
font-weight: 400;
line-height: 135%;
letter-spacing: -3px;
`

const FourthMainContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin-top: 5vh;
padding: 0px 0px 0px 5vw;
`
const FourthMainLeftContainer = styled.div`
`
const FourthMainLeftTopContainer = styled.div`

`
const FourthMainRightContainer = styled.div`
`