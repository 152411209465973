import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { Arrow_Big_Icon, Avadot_Background_Img, Avadot_Logo, Bossline_Logo, PlumAi_Logo, Plum_BG_Apple_1, Plum_BG_Apple_2, Storist_Logo } from '../../../style/img';
import { css, keyframes } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const ProjectLogoContainer = styled.div`
width: auto;
height: auto;
display: flex;
flex-direction: column;


/* background: #141414; */
background: black;
`

const BotamSmartContainer = styled.div`
display: flex;
width: auto;
height: 500px;
`
const StoristContainer = styled.div`
display: flex;
width: auto;
height: 500px;
`
const AvadotContainer = styled.div`
display: flex;
width: auto;
height: 500px;
background-Image: url(${Avadot_Background_Img});
z-index: 2;
background-size: cover;
`
const MainDivStyle = styled.div`
display: flex;
justify-content: center;
align-items: center;

width: 50%;
background-color: ${(props) => (props.background)};
height: 500px;
`
const BotamSmartMainDivStyle = styled.div`
display: flex;
justify-content: center;
align-items: center;

width: 50%;
background-color: ${(props) => (props.background)};
height: 500px;
`

const ArrowIconDivStyle = styled.div`
width: 124px;
height: 124px;
margin: 20px; 
transition: margin 0.3s ease;
`

const SubSubMainDivStyle = styled.div`
height: 414px;
display: flex;
flex-direction: row;
justify-content: space-between;
`
const SubDivStyle = styled.div`
  width: 50%;
  background-color: ${(props) => props.background};
  height: 500px;

  /* isHovered 값에 따라 ArrowIconDivStyle의 margin 설정 */
  ${props =>
    props.isHovered &&
    css`
      &:hover ${ArrowIconDivStyle} {
        margin: 0px;
      }
    `}

&:hover{
    cursor:pointer;
}
`

const SubMainSpanStyle = styled.span`
display: flex;
margin-top: 60px;
margin-left: 60px;
color: #171717;
text-align: center;
font-family: ${(props) => props.font};
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 54px */
letter-spacing: -1.6px;
`
const ArrowIcon = styled.img`
`

const SubSubDivStyle = styled.div`
display: flex;
height: 86px;
align-items: center;
border-top: 0.5px solid #171717;
`
const SubSubSpanStyle = styled.span`
margin-left: 60px;
`
const LogoImgStyle = styled.img`
transform: translateY(${props => (props.offset ? `${props.offset}px` : '0')});
transition: transform 0.3s ease;
`
const BosslineLogoImgStyle = styled.img`
transform: translateY(${props => (props.offset ? `${props.offset}px` : '0')});
transition: transform 0.3s ease;
z-index:0;
`
const AvadotLogoImgStyle = styled.img`
transform: translateY(${props => (props.offset ? `${props.offset}px` : '0')});
transition: transform 0.3s ease;
z-index:0;
`

const PlumAiContainer = styled.div`
display: flex;
width: auto;
height: 500px;
background-color: #E9D8EA;
z-index: 3;
position: relative;
/* background-size: cover; */
`

const ProjectLogo = ({ scrollTop }) => {
    const [isSubDivHovered, setIsSubDivHovered] = useState(false);
    const navi = useNavigate();
    const offset = Math.max(0, scrollTop - 5500);
    const offsetStorist = Math.max(0, scrollTop - 5900);
    const offsetAvadot = Math.max(0, scrollTop - 6400);
    const offsetPlumAi = Math.max(0, scrollTop - 6700);

    const onClickSubDiv = (e) => {
        /* window.open(e); */
        navi(e)
    }

  return (
    <ProjectLogoContainer>
        <BotamSmartContainer>
            <MainDivStyle
            background = {'#242424'}
            >
                <BosslineLogoImgStyle src={Bossline_Logo} offset={offset}/>
            </MainDivStyle>
            <SubDivStyle
            background = {'#F0F0F0'}
            onMouseEnter={() => setIsSubDivHovered(true)} // 마우스가 들어오면 isSubDivHovered를 true로 설정
            onMouseLeave={() => setIsSubDivHovered(false)} // 마우스가 나가면 isSubDivHovered를 false로 설정
            isHovered={isSubDivHovered}
            onClick={(e)=>onClickSubDiv("project/botamsmart")}
            >
                <SubSubMainDivStyle>
                    <div>
                        <SubMainSpanStyle font = {"Pretendard_Bold"}>
                            보탐스마트
                        </SubMainSpanStyle>
                    </div>
                    <ArrowIconDivStyle>
                        <ArrowIcon src={Arrow_Big_Icon} />
                    </ArrowIconDivStyle>
                </SubSubMainDivStyle>
                <SubSubDivStyle>
                    <SubSubSpanStyle>
                        게임 커뮤니티용 종합 관리 챗봇
                    </SubSubSpanStyle>
                </SubSubDivStyle>
            </SubDivStyle>
        </BotamSmartContainer>

        <StoristContainer>
            <SubDivStyle
            background = {'#F0F0F0'}
            onMouseEnter={() => setIsSubDivHovered(true)} // 마우스가 들어오면 isSubDivHovered를 true로 설정
            onMouseLeave={() => setIsSubDivHovered(false)} // 마우스가 나가면 isSubDivHovered를 false로 설정
            isHovered={isSubDivHovered}
            style = {{zIndex: 1}}
            onClick={(e)=>onClickSubDiv("project/storist")}
            >
                <SubSubMainDivStyle
                style={{background:'#D9FF97'}}
                >
                    <div>
                        <SubMainSpanStyle font = {"Pretendard"}>
                            Storist
                        </SubMainSpanStyle>
                    </div>
                    <ArrowIconDivStyle>
                        <ArrowIcon src={Arrow_Big_Icon} />
                    </ArrowIconDivStyle>
                </SubSubMainDivStyle>
                <SubSubDivStyle
                style={{background:'#D9FF97'}}
                >
                    <SubSubSpanStyle>
                        이미지 생성 AI를 이용하여 스토리보드 제작을 손쉽게!
                    </SubSubSpanStyle>
                </SubSubDivStyle>
            </SubDivStyle>
            <MainDivStyle
            background = {'#242424'}
            >
                <LogoImgStyle src={Storist_Logo} offset={offsetStorist} />
            </MainDivStyle>
        </StoristContainer>

        <AvadotContainer>
            <MainDivStyle
            style={{ borderRight: '0.5px solid #171717' }} 
            >
                <AvadotLogoImgStyle src = {Avadot_Logo} offset = {offsetAvadot} />
            </MainDivStyle>
            <SubDivStyle
            onMouseEnter={() => setIsSubDivHovered(true)} // 마우스가 들어오면 isSubDivHovered를 true로 설정
            onMouseLeave={() => setIsSubDivHovered(false)} // 마우스가 나가면 isSubDivHovered를 false로 설정
            isHovered={isSubDivHovered}
            onClick={(e)=>onClickSubDiv("project/avadot")}
            >
                <SubSubMainDivStyle>
                    <div>
                        <SubMainSpanStyle font = {"Pretendard_Bold"}>
                            Avadot
                        </SubMainSpanStyle>
                    </div>
                    <ArrowIconDivStyle>
                        <ArrowIcon src={Arrow_Big_Icon} />
                    </ArrowIconDivStyle>
                </SubSubMainDivStyle>
                <SubSubDivStyle>
                    <SubSubSpanStyle>
                        생성형 AI 로 디지털 휴먼, 아바타를 만들어 공유하고 투표를 통해 3D 디지털 휴먼으로 전환
                    </SubSubSpanStyle>
                </SubSubDivStyle>
            </SubDivStyle>
        </AvadotContainer>

        <PlumAiContainer>
            <PlumBGIma1 />
            <PlumBGIma2 />
            <PlumAiSubDivStyle
            style={{ borderRight: '0.5px solid #171717' }} 
            onMouseEnter={() => setIsSubDivHovered(true)} // 마우스가 들어오면 isSubDivHovered를 true로 설정
            onMouseLeave={() => setIsSubDivHovered(false)} // 마우스가 나가면 isSubDivHovered를 false로 설정
            isHovered={isSubDivHovered}
            onClick={(e)=>onClickSubDiv("project/plumai")}
            >
                <SubSubMainDivStyle
                >
                    <div>
                        <SubMainSpanStyle font = {"Pretendard_Bold"}>
                            Plum Ai
                        </SubMainSpanStyle>
                    </div>
                    <ArrowIconDivStyle>
                        <ArrowIcon src={Arrow_Big_Icon} />
                    </ArrowIconDivStyle>
                </SubSubMainDivStyle>
                <SubSubDivStyle
                >
                    <SubSubSpanStyle>
                        대화형AI를 이용하여 이미지생성을 손쉽게!
                    </SubSubSpanStyle>
                </SubSubDivStyle>
            </PlumAiSubDivStyle>
            <MainDivStyle
            >
                <LogoImgStyle src={PlumAi_Logo} offset={offsetPlumAi} />
            </MainDivStyle>
        </PlumAiContainer>
    </ProjectLogoContainer>
  );
};

export default ProjectLogo;
const PlumBGIma1 = styled.div`
width: 715px;
height: 752px;
position: absolute;
top: 0px;
left: -10vw;
background-image: url(${Plum_BG_Apple_1});
opacity: 0.5;
z-index: 1;
`
const PlumBGIma2 = styled.div`
width: 1075px;
height: 989px;
position: absolute;
top: 0;
left: 40vw;
background-image: url(${Plum_BG_Apple_2});
opacity: 1;
z-index: 1;
`
const PlumAiSubDivStyle =styled.div`
width: 50%;
z-index: 2;
${props =>
    props.isHovered &&
    css`
      &:hover ${ArrowIconDivStyle} {
        margin: 0px;
      }
    `}
    cursor: pointer;
`