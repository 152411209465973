import React, { useState } from 'react';
import styled from "@emotion/styled/macro";

const RecruitContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 188px;
background: #44E46E;
overflow-y: hidden;
`
const RecruitLeftDiv = styled.div`
width: 200px;
color: #141414;
font-family: Pretendard_Black;
font-size: 40px;
font-style: normal;
font-weight: 900;
line-height: 135%; /* 54px */
letter-spacing: -1.6px;
position: absolute;
left: 10.68%;
`
const RecruitRightDiv = styled.div`
color: #000;
font-family: Pretendard;
font-size: ${(props) => (props.hover ? "21.6px" : "18px")};
font-style: normal;
font-weight: 600;
line-height: 109.867%; /* 23.731px */
letter-spacing: ${(props) => (props.hover ? "-1.08px" : "-0.9px")};
transition: 0.4s ease, transform 0.4s ease;

display: flex;
position: absolute;
left: 67.71%;
`

const Recruit = () => {
    const [hoverRecruit, setHoverRecruit] = useState(false);
  return (
    <RecruitContainer
        onMouseEnter={() => setHoverRecruit(true)}
        onMouseLeave={() => setHoverRecruit(false)}>
        <RecruitLeftDiv>
            <span>Recruit</span>
        </RecruitLeftDiv>
        <RecruitRightDiv
            hover={hoverRecruit}>
            <span>
                조이스 채용정보 알아보러 가기
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                <path d="M0.800491 21.5998L16.6406 5.75977" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16.6429 17.28V5.76001H5.12277" stroke="black" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </RecruitRightDiv>
    </RecruitContainer>
  );
};

export default Recruit;