import React, { useEffect, useRef, useState } from 'react';
import styled from "@emotion/styled/macro";
import { Joys_Emoji_Small_Img, Mouse_Event_Text } from '../../../style/img';
import { keyframes } from '@emotion/react';

const EmojiContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
position: relative;

left: 5%;
top: -200px;

width: 165px;
height: 165px;
flex-shrink: 0;
/* background-color: red; */
z-index: 9;
`
const boxFade = keyframes`
  0%, 100% {
    margin-top: 0px;
  }
  50% {
    margin-top: 10px;
  }
`
const EmojiImg = styled.img`
  position: absolute;
  /* margin-top: ${(props) => props.marginTop}px; */
  /* padding: 0.357px 0px 15.278px 0px; */
  /* background-color: red; */

  animation: ${boxFade} 1.4s linear infinite; 
  animation-delay: 0.5s; 
`
const EmojiText = styled.img`
`
const EmojiExplain = () => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const startTimestamp = performance.now();

    const updateRotation = () => {
      const currentTimestamp = performance.now();
      const elapsedTime = currentTimestamp - startTimestamp;
      const speed = Math.min(1, elapsedTime / 6000); // Speed should be between 0 and 1

      // Apply rotation with increasing speed (from 0 to 360 degrees)
      const newRotation = (rotation + speed * 360) % 360;
      setRotation(newRotation);

      requestAnimationFrame(updateRotation);
    };

    requestAnimationFrame(updateRotation);

    return () => {
      // Cleanup on component unmount
      cancelAnimationFrame(updateRotation);
    };
  }, [rotation]);
  return (
        <EmojiContainer>
            <EmojiImg src={Joys_Emoji_Small_Img}/>
            <EmojiText src={Mouse_Event_Text} style={{ transform: `rotate(${rotation}deg)` }}/>
        </EmojiContainer>
  );
};

export default EmojiExplain;