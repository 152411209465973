import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { Avadot_Icon, Avadot_Logo, Project_Avadot_1, Project_Avadot_1_BG_Webp, Project_Avadot_2, Project_Avadot_3, Star_Tridot } from '../../style/img';
import { useXScroll } from '../../module/xscroll';

const AvadotContainer = styled.div`
max-width: 2000px;
height: auto;
overflow-x: hidden;
`
const FirstDivStyle = styled.div`
height: 948px;
`
const TopDivStyle = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 97px ;
gap: 30%;
`

const LeftSpanDivStyle = styled.div`
display: flex;
flex-direction: column;
z-index: 1;
`
const MainSpanStyle = styled.span`
color: var(--text, #E7E7E7);
font-family: Pretendard;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 135%; /* 54px */
letter-spacing: -1.6px;
`
const SubSpanStyle = styled.span`
color: #E5E6E1;
font-family: Pretendard;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 135%; /* 27px */
letter-spacing: -0.8px;
`

const RightSpanDivStyle = styled.div`
height: 34px;
display: flex;
justify-content: center;
align-items: center;
padding: 0px 20px;


z-index: 1;
`
const RightSpanStyle = styled.span`
color: #262626;
font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 109.867%; /* 17.579px */
letter-spacing: -0.8px;
`

const MainDivStyle= styled.div`
display: flex;
justify-content: center;
align-items: center;
background-image: url(${Project_Avadot_1_BG_Webp});
background-size: cover;
margin-top: -200px;
`
const MainImgStyle = styled.img`
max-width: 1600px;
z-index: 0;
transform: translateY(${props => (props.offset ? `${props.offset}px` : '-50px')});
transition: transform 0.1s ease;
`
const IconImgStyle = styled.img`
display: flex;
justify-content: center;
align-items: center;
position: absolute;
margin-left: 15%;
margin-top: -155px;
z-index: 3;
`

const SecondDivStyle = styled.div`
position: relative;
display: flex;
justify-content: center;
align-items: center;
height: 377px;
background: #141414;
box-shadow: 0px -15px 32px 0px rgba(0, 0, 0, 0.08);
gap:18%;
z-index: 2;
`
const AvadotLogoImg = styled.img`
width: 230px;
`
const SecondSpanStyle = styled.span`
color: #ECEDE4;
font-family: Pretendard;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 18.9px */
letter-spacing: -0.7px;
//
width: 589px;
white-space:clip;
`

const ThirdDivStyle = styled.div`
height: 942px;
background: #7A8086;
z-index: 1;
position: relative;
overflow-y: hidden;
overflow-x: hidden;
`
const ImgDivStyle = styled.div`
z-index: 1;
display: flex;
`
const ImgLeftDivStyle = styled.div`
background-image: ${(props) => `url(${props.background})`};
background-repeat: no-repeat;
display: flex;
flex-direction: column;
position: absolute;
top: 23%;
left: 9%;
`
const StudioDoppleStyle = styled.img`
display: flex;
position: absolute;
top:350px;
`

const ImgLeftSpanStyle = styled.span`
color: #FFF;
font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 32.4px */
letter-spacing: -1.2px;
`

const ImgRightDivStlye = styled.div`
z-index: 1;
margin-left: -10%;
`
const ThirdLeftSpan = styled.span`
color: #FFF;
font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 32.4px */
letter-spacing: -1.2px;
`
const ThirdBackGroundImg = styled.img`
max-width: 2100px;
margin-top: -220px;
z-index: -1;
/* position: relative; */
`

//Fourth

const FourthDivStyle = styled.div`
height: 1000px;
position: relative;
overflow-y: hidden;
overflow-x: hidden;
`
const FourthSpan = styled.span`
color: #141414;
font-family: Pretendard;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 135%; /* 32.4px */
letter-spacing: -1.2px;

display: flex;
position: absolute;
top: 50%;
left: 1300px;
`
const FourthBackGroundImg = styled.img`
max-width: 2000px;
margin-top: -200px;
z-index: -1;
position: relative;
`

const Avadot = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const firstIntroduceElement = document.getElementById('first-introduce');
    const handleScroll = () => {
      if (!firstIntroduceElement) return;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      console.log(scrollTop)
      if(scrollTop<700){
        setScrollTop(scrollTop);
      }      
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const offset = Math.max(0, scrollTop - 200);
 /*  console.log(offset) */
 useEffect(() => {
  // 페이지가 렌더링될 때 맨 위로 스크롤 이동
  window.scrollTo(0, 0);
}, []);
  return (
    <AvadotContainer>
      <FirstDivStyle id = "first-introduce">
      <br />
        <TopDivStyle>
          <LeftSpanDivStyle>
            <MainSpanStyle>
              Avadot
            </MainSpanStyle>
            <SubSpanStyle>
              생성형 AI 로 디지털 휴먼, 아바타를 만들어 공유하고 투표를 통해 3D 디지털 휴먼으로 전환
            </SubSpanStyle>
          </LeftSpanDivStyle>

          <RightSpanDivStyle>
{/*         <RightSpanStyle>
              MVP 출시완료
            </RightSpanStyle> */}
          </RightSpanDivStyle>
        </TopDivStyle>
        <MainDivStyle>
          <MainImgStyle src = {Project_Avadot_1} offset={offset}/>
        </MainDivStyle>
        <IconImgStyle src={Avadot_Icon} />
      </FirstDivStyle>

      <SecondDivStyle>
        <AvadotLogoImg src = {Avadot_Logo} />
        <SecondSpanStyle>
          아바닷은 복잡한 프롬프트 없이도 디지털 휴먼 이미지를 생성할 수 있으며, 생성한 이미지를 플랫폼에서 공유
          하고 랭킹할 수 있는 서비스입니다. 자신만의 독특한 캐릭터를 만들어 공유하고, 다른 사용자들과 경쟁하며 랭
          킹에서 상위에 올라갈 수 있는 즐거움을 느껴보세요. 랭킹 1위로 선정된 캐릭터는 추후에 3D로 제작됩니다. 이
          를 통해 창작물을 현실로 구현하는 특별한 경험을 제공합니다.
        </SecondSpanStyle>
      </SecondDivStyle>

      <ThirdDivStyle>
        <ImgRightDivStlye>
          <ThirdBackGroundImg src={Project_Avadot_2} />
        </ImgRightDivStlye>
        <br />
        <ImgDivStyle>
          <ImgLeftDivStyle >
            <ThirdLeftSpan>생성모델과 로라를 이용하여 고퀄리티의
              <br />
              이미지를 복잡한 프롬프트 없이 생성합니다.
            </ThirdLeftSpan>
            <StudioDoppleStyle src={Star_Tridot} />
          </ImgLeftDivStyle>
        </ImgDivStyle>
      </ThirdDivStyle>
      
      <FourthDivStyle>
        <div>
          <FourthBackGroundImg src={Project_Avadot_3} />
          <FourthSpan>
            생성한 디지털 휴먼에게 스토리를
            <br />
            부여하고 다른 크리에이터와 소통합니다.
          </FourthSpan>
        </div>
      </FourthDivStyle>

    </AvadotContainer>
  );
};

export default Avadot;