import { combineReducers } from "redux";
import counter from "./counter";
import headerinfo from "./miain/headerinfo";
import messagesinfo from "./miain/messages/messagesinfo"
const rootReducer = combineReducers({
    counter,
    headerinfo,
    messagesinfo,
});

export default rootReducer;
