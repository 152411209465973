import React from 'react';
import { Route, Routes } from "react-router-dom";
import ImageTech from './components/ImageTech/ImageTech';
import Main from './components/Main/Main';
import Avadot from './components/Project/Avadot';
import BotamSmart from './components/Project/BotamSmart';
import Storist from './components/Project/Storist';
import RouteChangeTracker from './module/RouteChangeTracker';
import PlumAi from './components/Project/PlumAi';

const AppRouter = () => {
  RouteChangeTracker(); //google analytics 추적

  return (
    <>
    <Routes>
        <Route path="/" element={<Main />} />

        <Route path="/imagetech" element = {<ImageTech/>} />        
        <Route path="/project/botamsmart" element = {<BotamSmart/>} />        
        <Route path="/project/storist" element = {<Storist/>} />        
        <Route path="/project/avadot" element = {<Avadot/>} /> 
        <Route path="/project/plumai" element = {<PlumAi/>} />        
    </Routes>
    </>
  );
};

export default AppRouter;