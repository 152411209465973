import React from 'react';
import styled from "@emotion/styled/macro";
import { keyframes } from '@emotion/react';
import { Character_img, Shadow_img } from '../../style/img';
import { setagreeclicktrue } from '../../module/miain/messages/messagesinfo';
import { useDispatch } from 'react-redux';

const boxFade = keyframes`
  0%, 100% {
    margin-top: 0px;
  }
  50% {
    margin-top: 40px;
  }
`

const AiAgreeContainer = styled.div`
display: flex;
width: 100%;
height: 100vh;

background: #E7E7E7;

display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
`

const CharacterBoxStyle = styled.div`
display: flex;
flex-direction: column;
width: 314px;
`
const Character_Img = styled.img`
position: absolute;

animation: ${boxFade} 1.4s linear infinite; // 속도, 무한반복
animation-delay: 0.5s; // 
`
const Shadow_Img = styled.img`
margin-top: 320px;
`

const ButtonSetStyle = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
//
color: #000;
font-family: 'Pretendard';
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -1.12px;
`
const SkipBtn = styled.div`
display: flex;
align-items: center;
justify-content: center;

width: 257px;
height: 41px;
gap: 10px;

border-radius: 40px;
border: 1px solid #A9A9A9;

margin-right: 8px;
cursor:pointer;
`
const AgreeBtn = styled.div`
margin-left: 8px;

display: flex;
justify-content: center;
align-items: center;

width: 257px;
height: 41px;
gap: 10px;

border-radius: 40px;
background: #44E46E;
box-shadow: 4px 4px 44px 0px #B5F5B4;
cursor:pointer;
`

const AiAgree = (props) => {
  const dispatch = useDispatch();

  const onClickSkip = () => {
    window.scrollTo({top:3124});
  };

  const onClickAgree = () => {
    dispatch(setagreeclicktrue())
  };
  
  return (
    <AiAgreeContainer>
      <CharacterBoxStyle>
        <Character_Img src={Character_img} />
        <Shadow_Img src={Shadow_img} />
      </CharacterBoxStyle>
      <ButtonSetStyle>
        <SkipBtn onClick={onClickSkip}>
          건너뛰기
        </SkipBtn>
        <AgreeBtn onClick={onClickAgree} >
          대화형 이미지 생성 AI 체험하기
        </AgreeBtn>
      </ButtonSetStyle>
    </AiAgreeContainer>
  );
};

export default AiAgree;