import React, { useCallback, useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { Character_img, Shadow_img , Left_Small_Message, Left_Medium_Message, Right_Typing_Message, Right_Meduim_Message, Left_Typing_Message, Right_Small_Message, Left_Large_Message, Ai_Img_1, Ai_Img_2, Ai_Img_3, Ai_Img_4} from '../../style/img';
import { keyframes } from '@emotion/react';
import { setagreeclickfalse } from '../../module/miain/messages/messagesinfo';
import { useDispatch } from 'react-redux';

const AiMessagesContainer = styled.div`
display: flex;
flex-direction: row;
/* justify-content: center; */
/* align-items: center; */
width: 100%;
height: 100%;
background: #E7E7E7;

//스크롤을 내려도 화면을 따라오도록
position: fixed;
top: 0;
`
const boxFade = keyframes`
  0%, 100% {
    margin-top: 0px;
  }
  50% {
    margin-top: 40px;
  }
`
const CharacterBoxStyle = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 314px;

margin-left: 150px ;
`
const Character_Img = styled.img`
position: absolute;

animation: ${boxFade} 1.4s linear infinite; // 속도, 무한반복
animation-delay: 0.5s; // 
`
const Shadow_Img = styled.img`
margin-top: 320px;
`

const LeftMessageBoxDiv = styled.div`
  margin-top: 1000px;
  transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션  */
  opacity: 0;

  &.scroll-1 {
    opacity: 1;
    margin-top: 136px;
  }
  &.scroll-2 {
    opacity: 0;
    margin-top: -100px;
  }
`;

const LeftSmallMessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${Left_Small_Message});
  width: 369px;
  height: 91px;
`

const LeftTypingMessageBox = styled.div`
margin-top: 170px;
width: 180px;
height: 91px;
background-image: url(${Left_Typing_Message});

transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션  */
opacity: 0;
&.scroll-8 {
    opacity: 1;
  }
&.scroll-9 {
    opacity: 1;
    margin-top: -400px;
}
&.scroll-15 {
  opacity: 1;
  margin-top:0px;
}
`
const LeftTypingMessageBox2 = styled.div`
position: absolute;
top: 1000px;
width: 180px;
height: 91px;
background-image: url(${Left_Typing_Message});

transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션  */
opacity: 0;

&.scroll-15 {
  opacity: 1;
  top: 290px;
}
&.scroll-16{
  opacity: 0;
  top: -1000px;
}
`
const LeftTypingMessageBox3 = styled.div`
position: absolute;
top: 1000px;
width: 180px;
height: 91px;
background-image: url(${Left_Typing_Message});

transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션  */
opacity: 0;

&.scroll-24 {
  opacity: 1;
  top: 290px;
}
&.scroll-25{
  opacity: 0;
  top: -1000px;
}
`

const LeftImgBoxStyle = styled.div`
margin-top: 300px ;
width: 400px;
height: 200px;
border-radius: 29px;
background: #D9D9D9;
transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션 */

opacity: 0;

&.scroll-10 {
    opacity: 1;
    background-image: url(${Ai_Img_1});
    background-size: cover;

    width: 442px;
    height: 442px;
  }
  &.scroll-11 {
    opacity: 0;
  }
  &.scroll-15 {
    opacity: 0;
  }
`

const LeftImgBoxStyle2 = styled.div`
margin-top: 1000px;
width: 400px;
height: 200px;
border-radius: 29px;
background: #D9D9D9;
transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션 */

opacity: 0;

&.scroll-16 {
    opacity: 1;
    margin-top: 700px;
    background-image: url(${Ai_Img_2});
    background-size: cover;

    width: 442px;
    height: 442px;
}
`

const LeftImgBoxStyle3 = styled.div`
margin-top: 1100px;
width: 400px;
height: 200px;
border-radius: 29px;
background: #D9D9D9;
transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션 */

opacity: 0;

&.scroll-21 {
    opacity: 1;
    margin-top: -1100px;
    background-image: url(${Ai_Img_3});
    background-size: cover;

    width: 442px;
    height: 442px;
}
&.scroll-22 {
    opacity: 0;
    margin-top: -2100px;
}
`
const LeftImgBoxStyle4 = styled.div`
margin-top: 1100px;
width: 400px;
height: 200px;
border-radius: 29px;
background: #D9D9D9;
transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션 */

opacity: 0;

&.scroll-26 {
    opacity: 1;
    margin-top: 210px;
    background-image: url(${Ai_Img_4});
    background-size: cover;

    width: 442px;
    height: 442px;
}
&.scroll-27 {
    opacity: 0;
    margin-top: -2210px;
    background-image: url(${Ai_Img_4});
    background-size: cover;

    width: 442px;
    height: 442px;
}
`
const LeftImgBoxStyle5 = styled.div`
margin-top: 1100px;
width: 400px;
height: 200px;
/* background: #D9D9D9; */
transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션 */

opacity: 1;

&.scroll-30 {
    opacity: 1;
    margin-top: 1960px;
    /* background-size: cover; */

    width: 442px;
    height: 442px;
}
`
const LastImg = styled.img`
  width: 342px;
  height: 342px;
border-radius: 29px;
`
const LastImgDiv = styled.div`
display: flex;
flex-direction: row;

`
const LastButtonSetDiv = styled.div`
display: flex;
flex-direction: row;
position: absolute;
left: 625px;
`
const LastButtonDiv = styled.div`
display: flex;
width: 257px;
height: 41px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 40px;
border: 1px solid #A9A9A9;
margin-top: 30px;
margin-left: 10px;
//
font-family: Pretendard_Regular;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 135%; /* 21.6px */
letter-spacing: -0.16px;

&:hover{
  cursor: pointer;
  background: #44E46E;
}
`

const LeftLargeMessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 500px;
  width: 459px;
  height: 91px;
  background-image: url(${Left_Large_Message});
  transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션 */
  opacity: 0;

  &.scroll-12 {
    opacity: 1;
    margin-top: 30px;
  }
  &.scroll-13 {
    opacity: 0;
  }
  &.scroll-13{
    opacity: 0;
    margin-top: -1000px;
  }
`
const LeftLargeMessageBox2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 500px;
  width: 459px;
  height: 91px;
  background-image: url(${Left_Large_Message});
  transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션 */
  opacity: 0;

  &.scroll-16 {
    opacity: 1;
    margin-top: 30px;
  }
  &.scroll-17{
    opacity: 0;
    margin-top: -1000px;
  }
`
const LeftLargeMessageBox3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 500px;
  width: 459px;
  height: 91px;
  background-image: url(${Left_Large_Message});
  transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션 */
  opacity: 0;

  &.scroll-22 {
    opacity: 1;
    margin-top: 30px;
  }
  &.scroll-23{
    opacity: 0;
    margin-top: -1000px;
  }
`
const RigthMessageStyle = styled.div`
`

const RightTypingMessageBox = styled.div`
  display: flex;
  margin-left: 220px;
  margin-top: 1000px;
  background-image: url(${Right_Typing_Message});
  transition: opacity , 0.4s ease, transform 0.4s ease; /* 트랜지션 = 부드러운 애니메이션 */
  width: 182px;
  height: 91px;
  opacity: 0;

  &.scroll-3 {
    opacity: 1;
    margin-top: 422px;
  }
  &.scroll-4 {
    opacity: 0;
    margin-top: 422px;
  }
`
const RightMeduimMessageBox = styled.div`
  margin-top: -91px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  width: 518px;
  height: 91px;
  transition: opacity , 0.4s ease, transform 0.4s ease;

  background-image: url(${Right_Meduim_Message});
  &.scroll-5 {
    opacity: 1;
  }
  &.scroll-6 {
    opacity: 1;
    margin-top: -400px;
  }
  &.scroll-7 {
    opacity: 0;
    margin-top: -1000px;
  }
`

const RightSmallMessageBox = styled.div`
margin-top: 2040px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  width: 331px;
  height: 91px;

  background-image: url(${Right_Small_Message});
  transition: opacity , 0.4s ease, transform 0.4s ease;

  &.scroll-14 {
    opacity: 1;
    margin-top: 1100px;
  }
  &.scroll-15 {
    opacity: 1;
    margin-top: 540px;
  }
  &.scroll-16{
  opacity: 0;
  margin-top: -1000px;
}
`
const RightSmallMessageBox2 = styled.div`
margin-top: 3040px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  width: 331px;
  height: 91px;

  background-image: url(${Right_Small_Message});
  transition: opacity , 0.4s ease, transform 0.4s ease;

  &.scroll-14 {
    opacity: 1;
    margin-top: -1030px;
  }
  &.scroll-15 {
    opacity: 1;
    margin-top: -1590px;
  }
  &.scroll-16{
  opacity: 0;
  margin-top: -2000px;
}
`
const RightSmallMessageBox3 = styled.div`
margin-top: 6000px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;

  width: 331px;
  height: 91px;

  background-image: url(${Right_Small_Message});
  transition: opacity , 0.4s ease, transform 0.4s ease;

  &.scroll-23 {
    opacity: 1;
    margin-top: -4180px;
  }
  &.scroll-24{
  opacity: 1;
  margin-top: -4700px;
  }
  &.scroll-25{
  opacity: 0;
  margin-top: -5700px;
  }
`


const LeftMediumMessageBox = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${Left_Medium_Message});
  width: 550px;
  height: 91px;
`

const CenteredSpan = styled.span`
padding: 27px 0px 31px 0px;
color: #454545;
font-family: 'Pretendard';
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -1.89px;
`
function throttle(func, delay) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    if (!timeout) {
      timeout = setTimeout(() => {
        func.apply(context, args);
        timeout = null;
      }, delay);
    }
  };
}

const AiMessages = (props) => {
  const dispatch = useDispatch();
  const [triggerNumber, setTriggerNumber] = useState(0);
  console.log(triggerNumber)
  
  //다음으로, 이전으로 트리거생성
  useEffect(() => {
    // 1초마다 prevNumber를 1씩 증가시키는 함수
    const increasePrevNumber = () => {
      setTriggerNumber((prevNumber) => prevNumber + 1);
    };

    // 1초마다 increasePrevNumber 함수 호출
    const intervalId = setInterval(increasePrevNumber, 1500);

    return () => {
      // 컴포넌트가 unmount 되면 clearInterval을 호출하여 인터벌을 정리
      clearInterval(intervalId);
    };
  }, []);
  
  // 디바운스 함수
  function debounce(func, delay) {
    let timer;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, arguments);
      }, delay);
    };
  }

  //새로고침시 스크롤 초기화
  useEffect(() => { 
    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(()=>{
    /* if(triggerNumber >= 18){
      dispatch(setagreeclickfalse())
      window.scrollTo(0, 2195);
    } */if(triggerNumber < 0){
      setTriggerNumber(0);
    }
    console.log(triggerNumber)
  },[triggerNumber])

  const onClickReset = () => {
    setTriggerNumber(0);
    window.scrollTo(0, 0);
  }
  const onClickMain = () => {
    dispatch(setagreeclickfalse());
    window.scrollTo(0, 0); 
  }
  return (
    <AiMessagesContainer id="AiMessages">
      <CharacterBoxStyle>
        <Character_Img src={Character_img} />
        <Shadow_Img src={Shadow_img} />
      </CharacterBoxStyle>

      <div style={{marginLeft : "55px"}}>
        <LeftMessageBoxDiv className={triggerNumber === 0 ? '' : triggerNumber < 4 ? 'scroll-1' : 'scroll-2'}>
          <LeftSmallMessageBox>
            <CenteredSpan>안녕하세요, 전 조이스에요</CenteredSpan>
        </LeftSmallMessageBox>

          <LeftMediumMessageBox>
            <CenteredSpan> 저에게 생성하고 싶은 이미지를 설명해주세요. </CenteredSpan>
          </LeftMediumMessageBox>
        </LeftMessageBoxDiv>
        
        <LeftTypingMessageBox ightMeduimMessageBox className={triggerNumber === 4 ? 'scroll-8' : triggerNumber >= 5 ? 'scroll-9': ''}>
        </LeftTypingMessageBox>

        <LeftImgBoxStyle className={triggerNumber === 5 || triggerNumber === 6 || triggerNumber === 7 ? 'scroll-10' : triggerNumber >= 7 ? 'scroll-11' : ''}>
        </LeftImgBoxStyle>
        <LeftLargeMessageBox className={triggerNumber === 6 || triggerNumber === 7 ? 'scroll-12' : triggerNumber >= 7 ? 'scroll-13' : ''}>
          <CenteredSpan>변경하고 싶은게 있으면 말해주세요</CenteredSpan>
        </LeftLargeMessageBox>

        <LeftTypingMessageBox2 ightMeduimMessageBox className={triggerNumber === 12 ? 'scroll-15' : triggerNumber === 13 ? 'scroll-16':''}>
        </LeftTypingMessageBox2>
        <LeftImgBoxStyle2 className={triggerNumber === 9 || triggerNumber === 10 || triggerNumber === 11 ? 'scroll-16' :  triggerNumber >= 11 ? 'scroll-17' : ''}>
        </LeftImgBoxStyle2>

        <LeftLargeMessageBox2 className={triggerNumber === 10 || triggerNumber === 11 ? 'scroll-16' : triggerNumber === 12 ? 'scroll-17' : ''}>
          <CenteredSpan>변경하고 싶은게 있으면 말해주세요</CenteredSpan>
        </LeftLargeMessageBox2>

        <LeftImgBoxStyle3 className={triggerNumber === 13 || triggerNumber === 14 || triggerNumber === 15  ? 'scroll-21' :  triggerNumber >= 16 ? 'scroll-22' : ''}>
        </LeftImgBoxStyle3>
        <LeftLargeMessageBox3 className={triggerNumber === 14 || triggerNumber === 15 ? 'scroll-22' : triggerNumber === 12 ? 'scroll-23' : ''}>
          <CenteredSpan>변경하고 싶은게 있으면 말해주세요</CenteredSpan>
        </LeftLargeMessageBox3>

        <LeftTypingMessageBox3 ightMeduimMessageBox className={triggerNumber === 16 ? 'scroll-24' : triggerNumber === 17 ? 'scroll-25':''}>
        </LeftTypingMessageBox3>

        <LeftImgBoxStyle4 className={triggerNumber === 17 ? 'scroll-26' :triggerNumber >= 18 ? 'scroll-27' : ''}>
        </LeftImgBoxStyle4>

        <LeftImgBoxStyle5 className={triggerNumber >= 18 ? 'scroll-30' : ''}>
          <LastImgDiv>
            <LastImg src = {Ai_Img_1}/>
            <LastImg src = {Ai_Img_2}/>
          </LastImgDiv>
          <LastImgDiv>
            <LastImg src = {Ai_Img_3}/>
            <LastImg src = {Ai_Img_4}/>
          </LastImgDiv>
          <LastButtonSetDiv>
            <LastButtonDiv onClick={onClickReset}>
              <span>
                다시보기
              </span>
            </LastButtonDiv>
            <LastButtonDiv onClick={onClickMain}>
              <span>
                메인으로
              </span>
            </LastButtonDiv>
          </LastButtonSetDiv>
        </LeftImgBoxStyle5>
      </div>

      <RigthMessageStyle>
        <RightTypingMessageBox className={triggerNumber === 2 ? 'scroll-3' : triggerNumber >= 3 ? 'scroll-4': ''}></RightTypingMessageBox>
        <RightMeduimMessageBox className={triggerNumber === 3 ? 'scroll-5' : triggerNumber === 4 ? 'scroll-6' : triggerNumber >= 5 ? 'scroll-7' : ''}>
          <CenteredSpan>금발머리의 숏컷을 한 아시아 여자 이미지를 생성해줘</CenteredSpan>
        </RightMeduimMessageBox>
        <RightSmallMessageBox className={triggerNumber === 7 ? 'scroll-14' : triggerNumber === 8 ? 'scroll-15': triggerNumber === 9 ? 'scroll-16':''}>
          <CenteredSpan>머리색 검정색으로 바꿔줘</CenteredSpan>
        </RightSmallMessageBox>

        <RightSmallMessageBox2 className={triggerNumber === 11 ? 'scroll-14' : triggerNumber === 12 ? 'scroll-15': triggerNumber === 13 ? 'scroll-16':''}>
          <CenteredSpan>귀걸이를 다른걸로 바꿔줘</CenteredSpan>
        </RightSmallMessageBox2>
        
        <RightSmallMessageBox3 className={triggerNumber === 15 ? 'scroll-23' : triggerNumber === 16 ? 'scroll-24' :  triggerNumber >= 17 ? 'scroll-25' : ''}>
          <CenteredSpan>피부를 좀 더 밝게 해줘</CenteredSpan>
        </RightSmallMessageBox3>
      </RigthMessageStyle>
    </AiMessagesContainer>
  );
};

export default AiMessages;