import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import {
  Bossline_Logo,
  Character_img,
  MegaphoneEmoji,
  Project_BotamSmart_1,
  Project_BotamSmart_1_BG_Webp,
  Project_BotamSmart_2,
  Project_BotamSmart_3,
  Project_BotamSmart_4,
  Project_BotamSmart_5,
  Project_BotamSmart_6,
} from "../../style/img";
import { useNavigate } from "react-router-dom";
import { useXScroll } from "../../module/xscroll";

const BotamSmartContainer = styled.div`
  max-width: 2000px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
`;
const FirstContainer = styled.div`
  width: 100%;
  height: 878px;
`;
const FirstBackGroundContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 978px;
  background-image: url(${Project_BotamSmart_1_BG_Webp});
  background-size: cover;

  /*   pointer-events: ${(props) => (props.overFirst ? "none" : "auto")}; */
`;
const FirstMainImg = styled.img`
  max-width: 1600px;
  /* z-index: -1; */
  transform: translateY(
    ${(props) => (props.offset ? `${props.offset}px` : "100px")}
  );
  transition: transform 0.3s ease;
  opacity: ${(props) => (props.overFirst ? 0 : 1)};
  display: ${(props) => (props.overFirst ? "none" : "")};
`;
const FirstSpanDivStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  left: 10%;
  top: 8%;
  z-index: 1;
`;
const FirstMainSpan = styled.span`
  color: var(--text, #e7e7e7);
  font-family: Pretendard;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 54px */
  letter-spacing: -1.6px;
`;
const FirstSubSpan = styled.span`
  color: #e5e6e1;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 27px */
  letter-spacing: -0.8px;
`;
const FirstStarDivStyle = styled.div`
  display: flex;
  position: absolute;
  top: 700px;
  right: 200px;

  width: 620px;
  height: 163px;

  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
const FirstStarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
`
const FirstStarSpanStyle = styled.span`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  text-align: center; /* 추가된 스타일 */

//
color: #FFF;
text-align: center;
font-family: Pretendard;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 126.5%; /* 20.24px */
letter-spacing: 1px;
`

const FirstStarSVG = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="126" height="125" viewBox="0 0 126 125" fill="none">
<g filter="url(#filter0_b_982_1065)">
<path d="M46.6656 5.67517C48.8849 -0.289226 56.7708 -1.53665 60.7226 3.45157C63.7154 7.22927 69.3115 7.60766 72.7856 4.26723C77.373 -0.143597 85.0191 2.15405 86.4153 8.36292C87.4726 13.065 92.3504 15.834 96.9298 14.3318C102.977 12.3481 108.869 17.7358 107.433 23.9355C106.345 28.6308 109.538 33.2419 114.316 33.8754C120.625 34.7118 123.596 42.1223 119.612 47.0851C116.595 50.8435 117.471 56.3835 121.501 59.0272C126.822 62.518 126.283 70.4837 120.541 73.2265C116.192 75.3037 114.578 80.6753 117.061 84.8056C120.341 90.2594 116.399 97.2026 110.036 97.1821C105.216 97.1667 101.431 101.306 101.877 106.105C102.465 112.442 95.9013 116.987 90.1766 114.207C85.841 112.102 80.6349 114.189 78.9542 118.706C76.735 124.671 68.8491 125.918 64.8973 120.93C61.9045 117.152 56.3084 116.774 52.8342 120.114C48.2469 124.525 40.6007 122.228 39.2046 116.019C38.1472 111.317 33.2695 108.548 28.69 110.05C22.6432 112.033 16.7512 106.646 18.1872 100.446C19.2747 95.7508 16.0815 91.1397 11.3037 90.5062C4.99503 89.6698 2.02411 82.2592 6.0079 77.2965C9.02492 73.5381 8.14858 67.9981 4.11883 65.3544C-1.20219 61.8636 -0.663574 53.8979 5.07895 51.1551C9.4279 49.0779 11.0421 43.7063 8.55844 39.576C5.27898 34.1222 9.22045 27.179 15.5843 27.1994C20.4038 27.2149 24.1888 23.0756 23.7432 18.2767C23.1549 11.9401 29.7185 7.39465 35.4433 10.1742C39.7788 12.2793 44.9849 10.1922 46.6656 5.67517Z"fill="#D9D9D9" fill-opacity="0.01"/>
<path d="M46.9468 5.77979C49.0846 0.0344671 56.6808 -1.16714 60.4874 3.63786C63.5902 7.55432 69.3918 7.94661 72.9936 4.48348C77.4124 0.234666 84.7777 2.44792 86.1226 8.42873C87.2188 13.3036 92.2757 16.1743 97.0233 14.6168C102.848 12.706 108.524 17.8958 107.14 23.8678C106.013 28.7355 109.323 33.516 114.277 34.1728C120.354 34.9785 123.215 42.1168 119.378 46.8973C116.25 50.7937 117.159 56.5372 121.336 59.278C126.462 62.6406 125.943 70.3138 120.412 72.9558C115.903 75.1092 114.229 80.6781 116.804 84.9602C119.963 90.2137 116.167 96.9018 110.037 96.8821C105.04 96.8661 101.116 101.157 101.578 106.133C102.145 112.236 95.8221 116.615 90.3076 113.937C85.8128 111.755 80.4155 113.919 78.6731 118.602C76.5353 124.347 68.9391 125.549 65.1324 120.744C62.0297 116.827 56.228 116.435 52.6263 119.898C48.2074 124.147 40.8421 121.934 39.4973 115.953C38.4011 111.078 33.3442 108.207 28.5965 109.765C22.7718 111.676 17.0962 106.486 18.4795 100.514C19.6069 95.6461 16.2964 90.8656 11.3432 90.2088C5.26618 89.4031 2.40438 82.2648 6.24185 77.4843C9.36969 73.5879 8.46115 67.8444 4.28339 65.1036C-0.842187 61.7409 -0.323353 54.0678 5.20824 51.4258C9.71693 49.2723 11.3904 43.7034 8.81553 39.4214C5.65654 34.1679 9.45323 27.4797 15.5834 27.4994C20.5799 27.5155 24.5039 23.2242 24.0419 18.249C23.4752 12.1451 29.7978 7.76661 35.3123 10.4441C39.807 12.6265 45.2044 10.4627 46.9468 5.77979Z" stroke="white" stroke-width="0.6"/>
</g>
<defs>
<filter id="filter0_b_982_1065" x="-11.5684" y="-11.6462" width="148.758" height="147.674" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImageFix" stdDeviation="6"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_982_1065"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_982_1065" result="shape"/>
</filter>
</defs>
</svg>
);

//SecondContainer
const SecondContainer = styled.div`
  width: 100%;
  height: 403px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #e6ecdf;
  box-shadow: 0px -15px 32px 0px rgba(0, 0, 0, 0.08);
  z-index: 1;
  gap: 16%;
`;
const SecondLeftDiv = styled.div``;
const SecondRightDiv = styled.div`
  width: 589px;
`;
const SecondLogoImg = styled.img`
  width: 249px;
`;

//Third
const SecondSpan = styled.span`
  color: #141414;
  font-family: "Pretendard";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 18.9px */
  letter-spacing: -0.7px;
`;
const ThirdContainer = styled.div`
  width: 100%;
  height: 1075px;
  z-index: 1;
  background-color: #e6ecdf;
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow-x: hidden;
`;
const ThirdShdowDiv = styled.div`
  display: flex;
  position: absolute;
  width: 99vw;
  height: 75px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.03) 0%,
    rgba(217, 217, 217, 0) 100%
  );
`;
const ThirdRightDivStyle = styled.div`
  margin-left: 145px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const ThirdLeftDivStyle = styled.div`
  width: 50%;
  width: 333.093px;
  height: 337.4px;
  display: flex;
  flex-direction: column;
  margin-top: 477px;
  margin-left: 200px;
  filter: drop-shadow(-13px 29px 19px rgba(52, 146, 77, 0.3));
`;
const ThirdRightSpanDiv = styled.div`
  margin-top: 16%;
  margin-left: 200px;
  width: 463px;
`;
const ThirdRightSpan = styled.span`
  color: #141414;
  font-family: "Pretendard";
  font-size: 16px;
  font-style: normal;
  font-weight: 00;
  line-height: 135%; /* 21.6px */
  letter-spacing: -0.8px;
`;
const BotamSmartImg2 = styled.div`
  display: flex;
  position: relative;
  /* flex-direction: column; */
  right: 0px;
  margin-top: auto;
`;
const ThirdLeftCharacterImg = styled.img`
  width: 268.146px;
  height: 273.938px;
  transform: rotate(5deg);
  filter: drop-shadow(-13px 29px 19px #34924d4d);
`;
const ThirdCharacterRelativeDivStyle = styled.div`
  width: 144.965px;
  height: 146.84px;
  transform: rotate(-30deg);
  flex-shrink: 0;

  display: flex;
  position: relative;
  left: 840px;
  top: -70px;
`;
const ThirdRightCharacterImg = styled.img`
  width: 116.7px;
  height: 119.221px;
  /* transform: rotate(188deg); */
  flex-shrink: 0;
  transform: scaleX(-1);
  filter: drop-shadow(17px 20px 4px rgba(52, 146, 77, 0.3));
`;
const ThirdLeftMegaPhoneImg = styled.img`
  width: 133px;
  display: flex;
  position: relative;
  top: -180px;
  left: 150px;
`;

//FourthDivStyle
const FourthDivStyle = styled.div`
  max-width: 2000px;
  width: 100%;
  background-image: url(${Project_BotamSmart_3});
  background-size: cover;
  background-position: 0 50%;
  height: 948px;
`;
const FourtMainDivStyle = styled.div`
  width: 361px;
  display: flex;
  position: relative;
  top: 50%;
  margin-left: 70vw;
`;
const FourtMainSpanStyle = styled.span`
  color: #fff;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  letter-spacing: -0.8px;
`;
//FifthDivStyle
const FifthDivStyle = styled.div`
  width: 100%;
  height: 1358px;
  background: radial-gradient(
    105.59% 95.63% at 48.4% 117.64%,
    #000 0%,
    #84ffa4 100%
  );
  overflow-x: hidden;
  overflow-y: hidden;
`;
const ProjectBotamSmartImg4 = styled.img`
  display: flex;
  margin-top: 60px;
  transform: translateY(
    ${(props) => (props.offset3 ? `${props.offset3}px` : "0")}
  );
  transition: transform 0.3s ease;
  margin-left: 10%;
`;
const ProjectBotamSmartImg5 = styled.img`
  display: flex;
  transform: translateY(
    ${(props) => (props.offset4 ? `${props.offset4}px` : "-0")}
  );
  transition: transform 0.3s ease;
  margin-left: 43%;
`;
const FifthSpanDivStyle = styled.div`
  display: flex;
  width: 380px;
  height: 245px;
  flex-shrink: 0;
  position: relative;
  transform: translateY(
    ${(props) => (props.offset5 ? `${props.offset5}px` : "-0")}
  );
  transition: transform 0.3s ease;
  top: -46%;
  left: 70vw;
`;
const FifthSpanStyle = styled.span`
  color: #141414;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  letter-spacing: -0.8px;
`;
//SixthDivStyle
const SixthDivStyle = styled.div`
  max-width: 2000px;
  width: 100%;
  height: 975px;
  background-image: url(${Project_BotamSmart_6});
  background-size: 100% 100%;
  background-position: 0 0%;
  display: flex;
  flex-direction: column;
  gap: 510px;
`;
const SixthMainDivStyle = styled.div`
  width: 370px;
  display: flex;
  margin-top: 10%;
  margin-left: 11%;
`;
const SixthMainSpanStyle = styled.span`
  color: #141414;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 21.6px */
  letter-spacing: -0.8px;
`;
const SixthSubDivStyle = styled.div`
  display: flex;
  width: 408px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 35px;
  border: 1px solid #44e46e;

  margin-left: 11%;
  /* margin-top: 300px; */
  &:hover {
    cursor: pointer;
    background-color: #141414;
  }
  z-index: 1;
`;
const SixthSubSpan = styled.span`
  color: #44e46e;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 32.4px */
  letter-spacing: -0.96px;
`;
const BotamSmart = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const [overFirst, setOverFirst] = useState(false);

  useEffect(() => {
    const firstIntroduceElement = document.getElementById("first-introduce");
    const handleScroll = () => {
      if (!firstIntroduceElement) return;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      console.log(scrollTop);
      if (scrollTop > 1) {
        setScrollTop(scrollTop);
        if (scrollTop > 800) {
          setOverFirst(true);
        } else {
          setOverFirst(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const offset = Math.max(0, scrollTop - 100);
  const offset3 = Math.max(0, scrollTop - 3300);
  const offset4 = Math.max(0, scrollTop - 3635);
  const offset5 = Math.max(0, scrollTop - 3700);

  useEffect(() => {
    // 페이지가 렌더링될 때 맨 위로 스크롤 이동
    window.scrollTo(0, 0);
  }, []);
  return (
    <BotamSmartContainer id="first-introduce">
      <FirstContainer>
        <FirstSpanDivStyle>
          <FirstMainSpan>보탐 스마트</FirstMainSpan>
          <FirstSubSpan>게임 커뮤니티 종합 관리 챗봇</FirstSubSpan>
        </FirstSpanDivStyle>

        <FirstBackGroundContainer>
          <FirstMainImg
            src={Project_BotamSmart_1}
            offset={offset}
            overFirst={overFirst}
          />
        </FirstBackGroundContainer>

        <FirstStarDivStyle>
          <FirstStarContainer>
            <FirstStarSpanStyle>
              누적 사용자
              <br />
              5만명
            </FirstStarSpanStyle>
            <FirstStarSVG />
          </FirstStarContainer>
          <FirstStarContainer>
            <FirstStarSpanStyle>
              DAU
              <br />
              1만명
            </FirstStarSpanStyle>
          <FirstStarSVG />
          </FirstStarContainer>
          <FirstStarContainer>
            <FirstStarSpanStyle>
              구독형 서비스
              <br />
              월 3만원
            </FirstStarSpanStyle>
          <FirstStarSVG />
          </FirstStarContainer>
          <FirstStarContainer>
            <FirstStarSpanStyle>
              30개 이상
              <br />
              게임에 진출
            </FirstStarSpanStyle>
          <FirstStarSVG />
          </FirstStarContainer>
        </FirstStarDivStyle>
      </FirstContainer>

      <SecondContainer>
        <SecondLeftDiv>
          <SecondLogoImg src={Bossline_Logo} />
        </SecondLeftDiv>
        <SecondRightDiv>
          <SecondSpan>
            보탐스마트는 유저들이 협력과 경쟁을 통해 게임을 즐기는 데에 도움을
            주는 유용한 서비스로, 혈맹 내의 팀워크를 강화하고 게임 플레이를
            최적화하는데 기여합니다. 대표적인 기능은 음성채팅 및 알림서비스이며,
            게임내 그룹으로 획득한 재화를 투명하게 공개하고 분배하는 등
            부가서비스를 제공합니다.
          </SecondSpan>
        </SecondRightDiv>
      </SecondContainer>
      <ThirdContainer>
        <ThirdShdowDiv></ThirdShdowDiv>

        <ThirdLeftDivStyle>
          <ThirdLeftCharacterImg src={Character_img} />
          <ThirdLeftMegaPhoneImg src={MegaphoneEmoji} />
        </ThirdLeftDivStyle>
        <ThirdRightDivStyle>
          <ThirdRightSpanDiv>
            <ThirdRightSpan>
              챗봇은 음성채팅을 제공하며, 보스 리젠 시간과 같은 중요한 알림을
              24시간 음성으로 안내합니다. 혈맹원들은 실시간으로 소통하고
              협력하여 보스를 잡는데 도움을 받을 수 있습니다.
            </ThirdRightSpan>
          </ThirdRightSpanDiv>

          <BotamSmartImg2>
            <ThirdCharacterRelativeDivStyle>
              <ThirdRightCharacterImg src={Character_img} />
            </ThirdCharacterRelativeDivStyle>
            <img src={Project_BotamSmart_2} />
          </BotamSmartImg2>
        </ThirdRightDivStyle>
      </ThirdContainer>

      <FourthDivStyle>
        <FourtMainDivStyle>
          <FourtMainSpanStyle>
            잡은 보스, 획득한 아이템 등 기록이 챗봇을 통해 자동으로 업데이트
            되며 이를 기록하고 저장합니다.
          </FourtMainSpanStyle>
        </FourtMainDivStyle>
      </FourthDivStyle>

      <FifthDivStyle>
        <ProjectBotamSmartImg4 src={Project_BotamSmart_4} offset3={offset3} />
        <ProjectBotamSmartImg5 src={Project_BotamSmart_5} offset4={offset4} />
        <FifthSpanDivStyle offset5={offset5}>
          <FifthSpanStyle>
            게임 내 그룹으로 획득한 아이템이나 재화들을 투명하게 공개하고
            효율적으로 분배하는 기능을 제공합니다.
          </FifthSpanStyle>
        </FifthSpanDivStyle>
      </FifthDivStyle>

      <SixthDivStyle>
        <SixthMainDivStyle>
          <SixthMainSpanStyle>
            보탐스마트는 혈맹원들의 활동 기록을 저장하고, 이를 통해 통계를 내어
            가장 기여도가 높은 혈원을 확인하는 기능을 제공합니다. 자신의
            기여도를 확인하고 목표도달에 도움을 주며, 혈맹의 성과를 파악하는데
            유용합니다.
          </SixthMainSpanStyle>
        </SixthMainDivStyle>
        <SixthSubDivStyle onClick={() => window.open("https://bossline.gg/")}>
          <SixthSubSpan>보탐스마트 바로가기</SixthSubSpan>
        </SixthSubDivStyle>
      </SixthDivStyle>
    </BotamSmartContainer>
  );
};

export default BotamSmart;
