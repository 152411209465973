import React, { useState } from 'react';
import styled from "@emotion/styled/macro";
import { FineTuning_Main_Img, FineTuning_Sub_Img } from '../../style/img';

const FineTuningImgContainer = styled.div`
width: auto;
height: 864px;
/* background: #141414; */

background-color: #141414;
`
const FineTuningFirstSpan = styled.span`
display: flex;
align-self: start;
margin-left: 80px;
color: #E7E7E7;
font-family: 'Pretendard';
font-size: 30px;
font-style: normal;
font-weight: 900;
line-height: 135%; /* 40.5px */
letter-spacing: -0.9px;
`
const FineTuningImgDivStyle = styled.div`
margin-top: 94px;
gap:47px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`

const FineTuningImg = () => {

  return (
    <FineTuningImgContainer>
        <FineTuningFirstSpan>Fine - tuning Images</FineTuningFirstSpan>
        <FineTuningImgDivStyle>
            <img src = {FineTuning_Main_Img} />
            <img src = {FineTuning_Sub_Img} />
        </FineTuningImgDivStyle>
    </FineTuningImgContainer>
  );
};

export default FineTuningImg;