import React, { useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import AiMessages from './AiMessages';
import AiAgree from './AiAgree';
import { useDispatch, useSelector } from 'react-redux';
import { setagreeclickfalse, setagreeclicktrue } from '../../module/miain/messages/messagesinfo';

const MessagesContainer = styled.div`
display: flex;
width: 100%;
height: ${(props) =>
    props.userAgree === true ? "3200px" : "100%"};

background: #141414;
z-index: 999;
`

const Messages = ({aiRef}) => {
  const dispatch = useDispatch();
  const {agreeclick} = useSelector((state) => state.messagesinfo);
  /* console.log(agreeclick) */
  
  const [prevUserAgree, setPrevUserAgree] = useState(false);

  const getAgreeValue = (value) => {
    setPrevUserAgree(agreeclick); // 현재 userAgree를 prevUserAgree로 저장
    if(value){
      dispatch(setagreeclickfalse())
    }else{
      dispatch(setagreeclicktrue())
    }
  };

  useEffect(() => {
    if (prevUserAgree === true && agreeclick === false) {
      window.scrollTo({top:2240});
    }
  }, [prevUserAgree, agreeclick]);

  return (
    <MessagesContainer userAgree = {agreeclick} ref = {aiRef}>
      {agreeclick === true ? <AiMessages agree = {agreeclick} getAgreeValue = {getAgreeValue} /> : <AiAgree agree = {agreeclick} getAgreeValue = {getAgreeValue} />}
    </MessagesContainer>
  );
};

export default Messages;