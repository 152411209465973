import React, { useCallback, useEffect, useState } from 'react';
import styled from "@emotion/styled/macro";
import { Arrow_Up_Img, TechPartBlur } from '../../../style/img';
import { useNavigate } from 'react-router-dom';

const TechPartContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 403px;
background: #BBB;
background-image: url(${TechPartBlur});
//호버 활성화 비활성화
backdrop-filter: blur(25px);

&:hover{
  cursor: pointer;
};
`

const TechPartMainContainer = styled.div`
z-index: 1;
`
const ArrowUpImg = styled.img`
margin-bottom: 12px;
`
const TechPartSpan = styled.span`
color: #FFF;
text-align: center;
font-family: Pretendard_Blod;
font-size: 22px;
/* font-style: normal; */
font-weight: 700;
/* line-height: normal; */
letter-spacing: -1.5px;
`
const TestDiv1 = styled.div`
  display: flex;
  position: absolute;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  left: 0px;
  top: 0px;
`
const TestDiv2 = styled.div`
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 400px;
  backdrop-filter: blur(25px);
  background-color: ${(props) => (props.hovered ? '#F378C9' : '')};;
`

/* const ColoredSVG = ({ hovered }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52" height="54" viewBox="0 0 52 54" fill="none">
    <rect width="52" height="54" fill={hovered ? '#F378C9' : ''}  />
  </svg>
); */

const calculateDivsNeeded = () => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const divWidth = 50;
  const divHeight = 50;

  const divsPerRow = Math.floor(screenWidth / divWidth);
  const rows = Math.floor(screenHeight / divHeight);

  return divsPerRow * rows;
};

const TechPart = ({ techPartRef }) => {
  const [hovered, setHovered] = useState(Array(calculateDivsNeeded()).fill(false));

  const navi = useNavigate();
  const onClick = () => {
    navi('/imagetech');
  };

  const handleResize = useCallback(() => {
    const divsNeeded = calculateDivsNeeded();
    setHovered((prevHovered) => {
      // If the new divsNeeded is greater, add more false values
      if (divsNeeded > prevHovered.length) {
        return [...prevHovered, ...Array(divsNeeded - prevHovered.length).fill(false)];
      }
      // If the new divsNeeded is smaller, remove extra values
      else if (divsNeeded < prevHovered.length) {
        return prevHovered.slice(0, divsNeeded);
      }
      // If divsNeeded hasn't changed, return the previous state
      else {
        return prevHovered;
      }
    });
  }, []);

  useEffect(() => {
    // Calculate divsNeeded initially
    const divsNeeded = calculateDivsNeeded();
    setHovered(Array(divsNeeded).fill(false));

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
<TechPartContainer
      ref={techPartRef}
      onClick={onClick}
      >
{/*         
      <TestDiv1>
        {hovered.map((isHovered, index) => (
          <TestDiv2
            key={index}
            hovered={isHovered}
            onMouseEnter={() => {
              const updatedHovered = [...hovered];
              updatedHovered[index] = true;
              setHovered(updatedHovered);
            }}
            onMouseLeave={() => {
              const updatedHovered = [...hovered];
              updatedHovered[index] = false;
              setHovered(updatedHovered);
            }}
          >
          </TestDiv2>
        ))}
      </TestDiv1>
 */}
      <TechPartMainContainer>
        <ArrowUpImg src={Arrow_Up_Img} />
        <TechPartSpan>이미지 생성 기술에 대해 더 알아보기</TechPartSpan>
      </TechPartMainContainer>        
    </TechPartContainer>
  );
};

export default TechPart;